import React from 'react'
import Header from '../components/Header'
import { Footer } from '../components/Footer'
import CreateAccountPage from '../views/createAccount/createAccountPage'

const CreateAccount = () => {
  window.scrollTo(0, 0);

  return (
    <div>
      <Header />
      <CreateAccountPage />
      <Footer />
    </div>
  )
}

export default CreateAccount
