import Login from "../views/loginSignup/Login"

export const login =()=>{
    window.scrollTo(0, 0);

    return(
        <>
        <Login/>
        </>
    )
}

export default login