import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const isTokenAvailable = localStorage.getItem('accessToken');
        if (isTokenAvailable) {
            setIsLoggedIn(true);
        }
        setIsLoading(false); // Set loading to false after checking
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Show a loading indicator while checking
    }

    return isLoggedIn ? (
        <>{children}</>
    ) : (
        <Navigate to="/login" replace />
    );
};

export default ProtectedRoute;
