import React, { useEffect, useState } from "react";
import patient from "../../assets/img/patient.jpg";
import rightArrow from "../../assets/img/arrow-right.svg";
import upload_icoon from "../../assets/img/upload_icoon.svg";
import tooltip_info_icon from "../../assets/img/tooltip_info_icon.svg"
// import { toast, ToastContainer } from "react-toastify"
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { patientInformation, patientManage } from "../../redux/action";
import { useNavigate } from "react-router";
import { showToast, ToastContainer } from "../../util/toastHelper";

const ManagePatientPage = () => {
  const selector = useSelector((state) => state.GET_MANAGE_PATIENT_REDUCER)
  const navigate = useNavigate()
  const [patientDetail, setPatientDetail] = useState({
    full_name: null,
    dob: null,
    email: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientDetail({ ...patientDetail, [name]: value });
  };


  const handleFileChange = (e) => {
    console.log(e)
    const { name, files } = e.target;
    let validFiles = [];

    if (name === "reports") {

      validFiles = [...files].filter((file) =>
        [".pdf", ".doc", "docx"].includes(file.name.slice(-4).toLowerCase())
      );

      if (validFiles.length !== files.length) {
        showToast("warn", 'Only .docs and .pdf formats are supported for reports.');
        return;
      }
      setPatientDetail({ ...patientDetail, reports: validFiles.slice(0, 5) });

    } else if (name === "audio") {
      const file = files[0];
      if (file && ![".mp3", ".wav", ".m4a"].includes(file.name.slice(-4).toLowerCase())) {
        showToast("warn", 'Only MP3, .WAV, and .M4A formats are supported for audio.');
        return;
      }
      console.log("Valid audio file: ", file);

      setPatientDetail({ ...patientDetail, audio: file });
    }
  };


  const dispatch = useDispatch()

  const handleSuccessCreatePatent = (response) => {
    // console.log("=============>===>", res)

    if (response.res.status == 200 || response.res.status == 201) {
      // Dispatch the success action after the API call succeeds
      console.log({ response })
      navigate('/patient-information')
    }
  }

  const handleSubmit = () => {
    console.log("Submitting patient details:", patientDetail);
    if (!patientDetail.full_name) {
      showToast("error", "Name is required.");
      return;
    }

    if (!patientDetail.email) {
      showToast("error", "Email is required.");
      return;
    }
    if (!patientDetail.phone) {
      showToast("error", "Phone number is required.");
      return;
    }

    if (!patientDetail.dob) {
      showToast("error", "Date of Birth is required.");
      return;
    }
    

    dispatch(patientManage({ ...patientDetail, cb: handleSuccessCreatePatent }))

    // Further submit logic here
  };

  const handleManagePatientEffect = () => {
    if (selector.error == null) {
      if (selector.data != null) {
        dispatch(patientInformation())
        showToast("success", selector.message);
      }
    } else {
      showToast("error", selector.error);
    }
  }


  console.log({ selector })

  useEffect(() => {
    handleManagePatientEffect()
  }, [selector])

  return (
    <div>
      <section class="site_hero patient_management_banner">
        <div class="site_container">
          <div class="site_row">
            <div class="txt_colm">
              <div class="txt_block">
                <h1>Manage Patient File</h1>
                <p>Manage each patient’s file efficiently</p>
              </div>
            </div>
            <div class="img_colm">
              <div class="img_block">
                <img src={patient} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="patient_form_block">
        <div class="site_container">
          <div class="patient_form">
            <div class="field_block">
              <label for="">Fullname*</label>
              <input
                type="text"
                name="full_name"
                className="text_field"
                placeholder="Enter Full Name"
                value={patientDetail.full_name}
                onChange={handleInputChange}
              />
            </div>

            <div class="field_block">
              <label for="">Email*</label>
              <input
                placeholder="Enter your email."
                type="text"
                name="email"
                className="text_field"
                value={patientDetail.email}
                onChange={handleInputChange}
              />
            </div>

            <div class="field_block">
              <label for="">Phone Number*</label>
              <input
                placeholder="Enter your phone number."
                type="text"
                name="phone"
                className="text_field"
                value={patientDetail.phone}
                onChange={handleInputChange}
              />
            </div>

            <div class="field_block">
              <label for="">Date of Birth*</label>
              <input type="date"
                name="dob"
                className="text_field"
                value={moment(patientDetail.dob).format("YYYY-MM-DD")}
                onChange={(e) =>
                  setPatientDetail({
                    ...patientDetail,
                    dob: moment(e.target.value).toISOString(),
                  })
                }
                max={moment().format("YYYY-MM-DD")}
              />
            </div>

            {/* <div className="field_block">
              <div className="upload_field">
                <div className="title">
                  <label>Upload Files</label>{" "}
                  <a
                    href="#"
                    data-bs-toggle="tooltip"
                    data-bs-title=" Allow our advanced AI models to learn how you like your reports to be written in your preferred tone and format. "
                    onClick={(e) => e.preventDefault()}
                  >
                    <img src={tooltip_info_icon} alt="Info" />{" "}
                  </a>
                </div>

                <div className="upload_ui">
                  <input
                    type="file"
                    name="reports"
                    multiple
                    onChange={handleFileChange}
                    accept=".doc, .docx, .pdf"
                  />
                  <div className="ui_inner">
                    <img src={upload_icoon} alt="Upload" />
                    <h5>Upload</h5>
                    <p>
                      <span>Browse</span> to upload the files
                    </p>
                  </div>
                </div>

                {patientDetail?.reports?.length > 0 ? (
                  <div className="sm_txt">
                    {patientDetail.reports.map((file, index) => (
                      <p key={index}>
                        <strong>{file.name}</strong>
                      </p>
                    ))}
                  </div>
                ) : (
                  <p className="sm_txt">
                    Supported file formats are <strong>.docs, .pdf</strong>.
                  </p>
                )}
              </div>
            </div>

            <div className="field_block">
              <div className="upload_field">
                <div className="title">
                  <label>Upload Audio Files</label>{" "}
                  <a
                    href="#"
                    data-bs-toggle="tooltip"
                    data-bs-title=" Allow our advanced AI models to learn how you like your reports to be written in your preferred tone and format. "
                    onClick={(e) => e.preventDefault()}
                  >
                    <img src={tooltip_info_icon} alt="Info" />{" "}
                  </a>
                </div>

                <div className="upload_ui">
                  <input
                    type="file"
                    name="audio"
                    accept=".mp3, .wav, .m4a"  // Restrict file types
                    onChange={handleFileChange}
                  />
                  <div className="ui_inner">
                    <img src={upload_icoon} alt="Upload" />
                    <h5>Upload</h5>
                    <p>
                      <span>Browse</span> to upload the file
                    </p>
                  </div>
                </div>

                {patientDetail.audio ? (
                  <p class="sm_txt">
                    <strong>{patientDetail?.audio?.name}</strong>
                  </p>
                ) : (
                  <p class="sm_txt">
                    Supported file formats are <strong>MP3, .WAV, .M4A.</strong>
                  </p>
                )}

              </div>
            </div> */}

            <div className="field_block">
              <div className="btn_row">
                <button onClick={handleSubmit}>
                  <span>Submit</span> <img src={rightArrow} alt="Arrow" />
                </button>
              </div>
            </div>


          </div>
        </div>
      </section>
      <ToastContainer />

    </div>
  );
};

export default ManagePatientPage;
