import { combineReducers } from "redux";
import {
    CLINICAL_INTAKE_REDUCER,
    GET_CREATE_ACCOUNT_REDUCER,
    GET_INFO_REDUCER,
    GET_MANAGE_PATIENT_REDUCER,
    GET_PATIENT_INFORMATION_REDUCER,
    // GET_PATIENT_MEDICINE_RECOMMENDATION_REDUCER,
    // GET_PATIENT_REPORT_REDUCER,
    GET_SIGNUP_REDUCER
} from "./reducer";

export default combineReducers(
    {
        GET_INFO_REDUCER: GET_INFO_REDUCER,
        GET_SIGNUP_REDUCER: GET_SIGNUP_REDUCER,
        GET_CREATE_ACCOUNT_REDUCER: GET_CREATE_ACCOUNT_REDUCER,
        GET_MANAGE_PATIENT_REDUCER: GET_MANAGE_PATIENT_REDUCER,
        GET_PATIENT_INFORMATION_REDUCER: GET_PATIENT_INFORMATION_REDUCER,
        CLINICAL_INTAKE_REDUCER: CLINICAL_INTAKE_REDUCER,
        // GET_PATIENT_MEDICINE_RECOMMENDATION_REDUCER: GET_PATIENT_MEDICINE_RECOMMENDATION_REDUCER,
        // GET_PATIENT_REPORT_REDUCER: GET_PATIENT_REPORT_REDUCER,
    }
)