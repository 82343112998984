const BASE_URL= process.env.REACT_APP_BACKEND_API_URL


console.log("base",BASE_URL)
export const LOGIN_API_URL = `${BASE_URL}auth/signin`; //POST
export const SIGNUP_API_URL = `${BASE_URL}auth/signup`; //POST
export const GET_SUBSCRIPTION = `${BASE_URL}subscription`; //GET
export const INIT_SUBSCRIPTION = `${BASE_URL}subscription/init`; //POST
export const SUCCESS_SUBSCRIPTION = `${BASE_URL}subscription/success`; //PUT
export const FAILURE_SUBSCRIPTION = `${BASE_URL}subscription/cancel`; //PUT
export const PRACTICE_FRAMEWORK = `${BASE_URL}practiceframework`; //GET
export const DIAGONASTIC_TOOL = `${BASE_URL}diagonastictool`; //GET
export const CREATE_ACCOUNT_API_URL = `${BASE_URL}user/info`; //POST
export const MANAGE_PATIENT_API_URL = `${BASE_URL}patientrecord`; //POST
export const PATIENT_INFORMATION_API_URL = `${BASE_URL}patientrecord`; //GET
// export const PATIENT_REPORT_API_URL = `${BASE_URL}patientrecord/reportcard/` //GET
export const PATIENT_REPORT_API_URL = `${BASE_URL}patientrecord/` //GET
export const PATIENT_PAITENT_REPORT_API_URL = `${BASE_URL}patientreportcard/` //GET
export const REPORT_TYPE_API_URL = `${BASE_URL}reporttype`; //GET
export const UPLOAD_CLICNICAL_INTAKE_DOC = `${BASE_URL}patientrecord/doc`; //GET
export const UPLOAD_CLICNICAL_INTAKE_FILE = `${BASE_URL}patientrecord/record`; //GET
export const UPLOAD_CLICNICAL_INTAKE_SUBMIT = `${BASE_URL}patientrecord/intake`; //post