import "./App.css";
import "./assets/scss/global.scss";
import "./assets/scss/slick.scss";
// import "./assets/scss/responsive.scss";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
// import "./assets/scss/slicktheme.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/home";
import Login from "./routes/login";
import Signup from "./views/loginSignup/Signup";
import { Subscription } from "./routes/subscription";
import { SubscriptionSuccess } from "./routes/subscriptionSuccess";
import { SubscriptionFailure } from "./routes/subscriptionFailure";
import { Privacy } from "./routes/privacy";
import { TermsCondition } from "./routes/termscondition";
import CreateAccount from "./routes/createAccount";
import ManagePatient from "./routes/managePatient";
import PatientInformation from "./routes/patientInformation";
import PatientMedicineInformation from "./routes/patientMedicineInformation";
import PatientReportInformation from "./routes/patientReportInformation";
import ProtectedRoute from "./routes/PrivateRoute";
import AboutUs from "./routes/about";
import Services from "./routes/Services";
import ContactUs from "./routes/ContactUs";
import ClinicalIntakeForm from "./routes/ClinicalIntakeForm";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/manage-patient" element={<ProtectedRoute><ManagePatient /></ProtectedRoute>} />
        <Route path="/patient-information" element={<ProtectedRoute><PatientInformation /></ProtectedRoute>} />
        {/* <Route path="/patient-medicine-information" element={<PatientMedicineInformation />} /> */}
        {/* <Route path="/patient-report-information" element={<PatientReportInformation />} /> */}
        <Route path="/patient-report-information/:id" element={<ProtectedRoute><PatientReportInformation /></ProtectedRoute>} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/subscription-success" element={<SubscriptionSuccess />} />
        <Route path="/subscription-failure" element={<SubscriptionFailure />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/clinical-intake-form" element={<ClinicalIntakeForm />} />
      </Routes>
    </>
  );
}

export default App;
