import React from 'react'
import Header from '../components/Header'
import { Footer } from '../components/Footer'
import ManagePatientPage from '../views/managePatient/managePatientPage'

const ManagePatient = () => {
  window.scrollTo(0, 0);

  return (
    <div>
        <Header/>
<ManagePatientPage/>
      <Footer/>
    </div>
  )
}

export default ManagePatient
