import Immutable from "seamless-immutable";
import {
  GET_CREATE_ACCOUNT_FAILED,
  GET_CREATE_ACCOUNT_HIT,
  GET_CREATE_ACCOUNT_SUCCESSED,
  GET_INFO_FAILED,
  GET_INFO_HIT,
  GET_INFO_SUCCEEDED,
  GET_MANAGE_PATIENT_FAILED,
  GET_MANAGE_PATIENT_HIT,
  GET_MANAGE_PATIENT_SUCCESSED,
  GET_PATIENT_INFORMATION_FAILED,
  GET_PATIENT_INFORMATION_HIT,
  GET_PATIENT_INFORMATION_SUCCESSED,
  GET_PATIENT_MEDICINE_RECOMMENDATION_FAILED,
  GET_PATIENT_MEDICINE_RECOMMENDATION_HIT,
  GET_PATIENT_MEDICINE_RECOMMENDATION_SUCCESSED,
  GET_PATIENT_REPORT_FAILED,
  GET_PATIENT_REPORT_HIT,
  GET_PATIENT_REPORT_SUCCESSED,
  GET_REPORT_TYPE_FAILED,
  GET_REPORT_TYPE_HIT,
  GET_REPORT_TYPE_SUCCESSED,
  GET_SIGNUP_FAILED,
  GET_SIGNUP_HIT,
  GET_SIGNUP_SUCCEEDED,
  LOGOUT,
  PATIENT_INFORMATION_HIT,
  PATIENT_INFORMATION_SUCCESSED,
} from "./constant";

export const infoData = {
  data: null, // Set an appropriate initial state
  isLoading: false,
  error: null,
  message: null,
};

export const signUpData = {
  data: null, // Set an appropriate initial state
  isLoading: false,
  error: null,
  message: null,
};

export const CreateAccountData = {
  data: null, // Set an appropriate initial state
  isLoading: false,
  error: null,
  message: null,
};

export const ManagePatientData = {
  data: null, // Set an appropriate initial state
  isLoading: false,
  error: null,
  message: null,
};

export const PatientInformationData = {
  data: null, // Set an appropriate initial state
  isLoading: false,
  error: null,
  message: null,
};

export const PatientMedicineRecommendationData = {
  data: null, // Set an appropriate initial state
  isLoading: false,
  error: null,
  message: null,
};

export const clinicalIntakeData = {
  data: null, // Set an appropriate initial state
  isLoading: false,
  error: null,
  message: null,
};


export const GET_INFO_REDUCER = (state = infoData, action) => {
  switch (action.type) {
    case GET_INFO_HIT:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        message: null,
      };
    case GET_INFO_SUCCEEDED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: action.data.data.user,
        error: null,
        message: action.data.message,
      };
    case GET_INFO_FAILED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.error,
        message: action.error,
      };

    case LOGOUT:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: null,
        message: null,
      };
    default:
      return state;
  }
};

export const GET_SIGNUP_REDUCER = (state = signUpData, action) => {
  switch (action.type) {
    case GET_SIGNUP_HIT:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        message: null,
      };
    case GET_SIGNUP_SUCCEEDED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: action.data.data.user,
        error: null,
        message: action.data.message,
      };
    case GET_SIGNUP_FAILED:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.error,
        message: action.error,
      };
    case LOGOUT:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: null,
        message: null,
      };
    default:
      return state;
  }
};

export const GET_CREATE_ACCOUNT_REDUCER = (
  state = CreateAccountData,
  action
) => {
  switch (action.type) {
    case GET_CREATE_ACCOUNT_HIT:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        message: null,
      };
    case GET_CREATE_ACCOUNT_SUCCESSED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: action.data.data.user,
        error: null,
        message: action.data.message,
      };
    case GET_CREATE_ACCOUNT_FAILED:
      console.log(action);

      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.error,
        message: action.error,
      };

    default:
      return state;
  }
};

export const GET_MANAGE_PATIENT_REDUCER = (
  state = ManagePatientData,
  action
) => {
  switch (action.type) {
    case GET_MANAGE_PATIENT_HIT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MANAGE_PATIENT_SUCCESSED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
        error: null,
        message: action.data.message,
      };
    case GET_MANAGE_PATIENT_FAILED:
      console.log(action);

      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.error,
        message: action.error,
      };

    default:
      return state;
  }
};

export const GET_PATIENT_INFORMATION_REDUCER = (
  state = PatientInformationData,
  action
) => {
  switch (action.type) {
    case GET_PATIENT_INFORMATION_HIT:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        message: null,
      };
    case GET_PATIENT_INFORMATION_SUCCESSED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
        error: null,
        message: action.data.message,
      };
    case GET_PATIENT_INFORMATION_FAILED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.error,
        message: action.error,
      };

    default:
      return state;
  }
};

export const CLINICAL_INTAKE_REDUCER = (
  state = clinicalIntakeData,
  action
) => {
  switch (action.type) {
    case GET_REPORT_TYPE_HIT:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
        message: null,
      };
    case GET_REPORT_TYPE_SUCCESSED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
        error: null,
        message: action.data.message,
      };
    case GET_REPORT_TYPE_FAILED:
      console.log(action);
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.error,
        message: action.error,
      };

    default:
      return state;
  }
};

// export const GET_PATIENT_MEDICINE_RECOMMENDATION_REDUCER = (
//   state = PatientMedicineRecommendationData,
//   action
// ) => {
//   switch (action.type) {
//     case GET_PATIENT_MEDICINE_RECOMMENDATION_HIT:
//       return {
//         ...state,
//         isLoading: true,
//         // data: null,
//         error: null,
//         message: null,
//       };
//     case GET_PATIENT_MEDICINE_RECOMMENDATION_SUCCESSED:
//       console.log(action);

//       const newData = action.data.data;
//       const existingData = state.data || [];

//       // Check if the data already exists by id
//       const updatedData = existingData.some(item => item.id === newData.id)
//         ? existingData.map(item =>
//           item.id === newData.id ? newData : item
//         ) // Update the item if exists
//         : [...existingData, newData]; // Add new item if it doesn't exist

//       return {
//         ...state,
//         isLoading: false,
//         data: updatedData,
//         error: null,
//         message: action.data.message,
//       };
//     case GET_PATIENT_MEDICINE_RECOMMENDATION_FAILED:
//       console.log(action);
//       return {
//         ...state,
//         isLoading: false,
//         data: null,
//         error: action.error,
//         message: action.error,
//       };

//     default:
//       return state;
//   }
// };


// export const GET_PATIENT_REPORT_REDUCER = (
//   state = PatientMedicineRecommendationData,
//   action
// ) => {
//   switch (action.type) {
//     case GET_PATIENT_REPORT_HIT:
//       return {
//         ...state,
//         isLoading: true,
//         // data: null,
//         error: null,
//         message: null,
//       };
//     case GET_PATIENT_REPORT_SUCCESSED:
//       console.log(action);

//       const newData = action.data.data;
//       const existingData = state.data || [];

//       // Check if the data already exists by id
//       const updatedData = existingData.some(item => item.id === newData.id)
//         ? existingData.map(item =>
//           item.id === newData.id ? newData : item
//         ) // Update the item if exists
//         : [...existingData, newData]; // Add new item if it doesn't exist

//       return {
//         ...state,
//         isLoading: false,
//         data: updatedData,
//         error: null,
//         message: action.data.message,
//       };
//     case GET_PATIENT_REPORT_FAILED:
//       console.log(action);
//       return {
//         ...state,
//         isLoading: false,
//         data: null,
//         error: action.error,
//         message: action.error,
//       };

//     default:
//       return state;
//   }
// };