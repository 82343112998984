import React from 'react'
import Header from '../components/Header'
import { Footer } from '../components/Footer'
import PatientInformationPage from '../views/patientInformation/patientInformationPage'

const PatientInformation = () => {
    window.scrollTo(0, 0);

    return (
        <div>
            <Header />
            <PatientInformationPage />
            <Footer />
        </div>
    )
}

export default PatientInformation
