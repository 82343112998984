import React, { useEffect, useState } from "react";
import patient from "../../assets/img/patient.jpg";
import rightArrow from "../../assets/img/arrow-right.svg";
import upload_icon from "../../assets/img/upload_icoon.svg";
import tooltip_info_icon from "../../assets/img/tooltip_info_icon.svg"

// import arrorRight from "../../assets/img/arrow-right.svg"
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { patientInformation, /*patientManage, patientMedicineRecommendation, patientReport*/ } from "../../redux/action";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import arrorRight from "../../assets/img/arrow-right.svg"

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CallWithAuth } from "../../action/apiActions";
import { UPLOAD_CLICNICAL_INTAKE_FILE } from "../../action/apiPath";

function MyVerticallyCenteredModal(props) {
  const [audio, setAudio] = React.useState(null);

  const handleFileChange = (e) => {
    const { files } = e.target;
    const file = files[0];

    if (file && ![".mp3", ".wav", ".m4a"].includes(file.name.slice(-4).toLowerCase())) {
      toast.warn('Only MP3, WAV, and M4A formats are supported for audio.', {
        autoClose: 2000,
        position: "bottom-right",
      });
      return;
    }
    console.log("Valid audio file: ", file);
    setAudio(file);
  };

  const handleSubmit = async () => {
    try {
      const formdata = new FormData();
      formdata.append("file", audio);

      const body = formdata;

      return await CallWithAuth("POST", UPLOAD_CLICNICAL_INTAKE_FILE, body);
    } catch (err) { }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload Audio File
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="patient_form_block">
          <div className="site_container">
            <div className="patient_form">
              <div className="field_block">
                <div className="upload_field">
                  <div className="title">
                    <label>Upload Audio Files</label>{" "}
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      data-bs-title="Allow our advanced AI models to learn how you like your reports to be written in your preferred tone and format."
                      onClick={(e) => e.preventDefault()}
                    >
                      <img src={tooltip_info_icon} alt="Info" />{" "}
                    </a>
                  </div>

                  <div className="upload_ui">
                    <label className="upload_ui_label">
                      <input
                        type="file"
                        name="audio"
                        accept=".mp3, .wav, .m4a"  // Restrict file types
                        onChange={handleFileChange}
                        style={{ display: 'none' }}  // Hide input element
                      />
                      <div className="ui_inner">
                        <img src={upload_icon} alt="Upload" />
                        <h5>Upload</h5>
                        <p>
                          <span>Browse</span> to upload the file
                        </p>
                      </div>
                    </label>
                  </div>

                  {audio ? (
                    <p className="sm_txt">
                      <strong>{audio.name}</strong>
                    </p>
                  ) : (
                    <p className="sm_txt">
                      Supported file formats are <strong>MP3, WAV, M4A.</strong>
                    </p>
                  )}
                </div>
              </div>

              <div className="field_block">
                <div className="btn_row">
                  <button onClick={handleSubmit}>
                    <span>Submit</span> <img src={rightArrow} alt="Arrow" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
}


const PatientInformationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.GET_PATIENT_INFORMATION_REDUCER)
  const [modalShow, setModalShow] = React.useState(false);
  const onHide = () => setModalShow(false)
  // const patientMedicineRecommendationSelector = useSelector((state) => state.GET_PATIENT_MEDICINE_RECOMMENDATION_REDUCER)
  // const patientReportSelector = useSelector((state) => state.GET_PATIENT_REPORT_REDUCER)

  useEffect(() => {
    getPatientInformation();
  }, [])

  const getPatientInformation = () => {
    if (selector.error === null) {
      if (selector.data === null) {
        dispatch(patientInformation())
      }
    }
  }


  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={onHide} />
      <div>
        <section class="site_hero patient_management_banner">
          <div class="site_container">
            <div class="site_row">
              <div class="txt_colm">
                <div class="txt_block">
                  <h1>Manage Patient File</h1>
                  <p>Manage each patient’s file efficiently</p>
                  <div class="btn_row">
                    <Link to={'/manage-patient'} class="solid_cta cta_primary cta_with_icon"><span>Add Patent</span> <img
                      src={arrorRight} alt="" /></Link>

                  </div>
                </div>
              </div>
              <div class="img_colm">
                <div class="img_block">
                  <img src={patient} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site_container">
          <div className="patient_form_block max-60">

            <table className="table table-bordered patient-row-details">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Date of birth</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {selector?.data?.length ?
                  selector?.data?.map((patient, index) => (
                    <tr key={index}>
                      <td><label>{patient.full_name}</label></td>
                      <td><label>{patient.dob.split("T")[0]}</label></td>
                      <td className="text-center">
                        <button className="solid_cta cta_primary cta_with_icon" style={{ border: 0, padding: '5px 8px' }} onClick={() => navigate(`/patient-report-information/${patient.id}`)}>
                          <span>Report</span> <img src={arrorRight} alt="" />
                        </button>
                        <button onClick={() => setModalShow(true)} className="edit"><i class="fa-solid fa-upload"></i></button>
                      </td>
                    </tr>
                  )) :
                  null
                }
              </tbody>
            </table>
            {selector?.data?.length === 0 && <h1 style={{ textAlign: "center" }}>No Data Found</h1>}
          </div>
        </section>
      </div>

    </>
  );
};

export default PatientInformationPage;
