import { Footer } from "../components/Footer"
import Header from "../components/Header"

export const TermsCondition = () => {
    window.scrollTo(0, 0);

    return (
        <>
            <Header />
            <section className="csm_page_wrapper">
                <div className="site_container">
                    <h1>Informed Consent for Audio Recording and Data Collection
                    For Use with PsychScribe AI Platform</h1>

                    <div>
                        <h3>
                        Purpose of Consent

                        </h3>
                    </div>
                    <p>As part of providing psychological and psychiatric services, we will audio record our sessions and collect various forms of data to assist in your care. This data will be processed using PsychScribe, an artificial intelligence (AI) platform designed to support clinicians by aiding in diagnostic decision-making, administrative support, and report generation. This consent document explains how your information will be collected, used, stored, and protected in compliance with the Health Insurance Portability and Accountability Act (HIPAA), the General Data Protection Regulation (GDPR), the Data Protection Act, the Australian Privacy Act 1988, and the Australian Privacy Principles (APP).</p>

                    <h3>   How PsychScribe Works</h3>

                    <h5>PsychScribe uses Artificial Intelligence to:</h5>

<p>	•	Assist in diagnostic decision-making by suggesting potential provisional diagnoses. However, all final diagnoses are verified by your clinician and require human/user interface. </p>
    <p>	•	Support administrative tasks, including generating reports and organising data to improve clinical efficiency.</p>
<p>	•	Provide automation to enhance the clinician’s ability to manage patient care in a timely manner including making individualised evidence-based treatment recommendations.</p>

<p>Please note: PsychScribe’s AI models are trained on anonymized and aggregated data to protect your privacy. The information collected during your sessions is handled with strict security measures to ensure confidentiality. <span style={{color:'red'}}>(we need to be clear on how the AI models are trained and whether patient data is retained to train the models) </span></p>

<h3>What Data is Collected and Why</h3>

<h5>We may collect the following types of information:</h5>

<p>•	Audio recordings of your sessions to ensure accurate documentation of conversations and treatment decisions.</p>
<p>	•	Session notes, including details about your health, treatment progress, and any relevant medical history.</p>
	<p>•	Personal information, such as demographic data, which helps in delivering accurate recommendations and tailored care.</p>
<p>	•	Treatment reports, which outline the interventions and progress made during your care.</p>
<p>	•	Medical reports from other healthcare professionals, relevant to your ongoing care and diagnosis.</p>
<p>	•	Psychology reports, containing assessments and diagnostic information that contribute to a comprehensive understanding of your mental health.</p>
<p>	•	Standardised psychometric assessment measures, which may include tests like cognitive assessments, mood inventories, and behavioural scales. These assessments provide quantitative data to aid in understanding your cognitive and emotional functioning and help inform your suggested provisional diagnoses and treatment. </p>

<h3>This data is collected for the following purposes:</h3>

<p>•	To help your clinician make informed decisions about your diagnosis and treatment plan.</p>
	<p>•	To generate reports and patient letters that provide a structured way for your clinician to monitor your progress over time.</p>
	<p>•	To improve the accuracy and functionality of PsychScribe’s AI system, which assists clinicians in making evidence-based decisions <span style={{color:'red'}}> (is data retained?).</span></p>
<p>	•	To integrate medical, psychological, and standardised psychometric data into a comprehensive care plan that addresses your specific needs.</p>

<h3>How Your Data is Stored</h3>

<h5>Your data will be stored in compliance with the following regulations:</h5>

<p>•	HIPAA (for US-based patients) mandates that your data is stored securely and is accessible only to authorised personnel.</p>
	<p>•	GDPR and the Data Protection Act (for patients in the EU and UK) ensure that your data is collected, processed, and stored lawfully. You have rights to access, correct, and delete your personal information.</p>
	<p>•	The Australian Privacy Act 1988 and Australian Privacy Principles (APP) (for Australian patients) require that your personal information is handled responsibly and transparently.</p>

<p>Your data is stored in encrypted databases within secure, regulated data centres that meet international security standards, ensuring that your information is safeguarded from unauthorised access. <span style={{color:'red'}}> (We also need to specify how long data is stored for)</span></p>

<h3>How PsychScribe Uses Your Data</h3>

<h5>The data collected from your sessions may be used to:</h5>

<p>	•	Train PsychScribe’s AI models in an anonymized and aggregated format to enhance system accuracy while ensuring that your personal information remains protected.</p>
	<p>•	Provide diagnostic suggestions to your clinician, which are then reviewed and confirmed by your clinician before being integrated into your care plan.</p>
	<p>•	Generate administrative reports to assist your clinician in efficiently managing your case and ensuring timely follow-up on treatment recommendations. </p>

<h3>Your Rights and Privacy Protections</h3>

<p>•	You have the right to access any data collected about you. However, this will need to be facilitated with your treating clinician and is not a responsibility of PsychScribe. </p>
	<p>•	You can request corrections to any inaccurate data. However, this will need to be facilitated with your treating clinician and is not a responsibility of PsychScribe as the final report and patient information will require final editing on your treating clinician’s behalf.  </p>
	<p>•	You may request that all or some of your data is deleted, subject to legal and regulatory obligations. However, this will need to be facilitated with your treating clinician and is not a responsibility of PsychScribe.</p>
	<p>•	Your data will not be shared with third parties without your explicit consent, except where required by law.</p>

<hr/>
<h3>Consent to Record and Collect Data</h3>

<p>By signing this form, you consent to the audio recording of your sessions and the collection of data, as outlined above, for the purposes of providing and enhancing your care through the PsychScribe platform. You understand that the AI platform is used as a support tool and that all diagnostic and treatment decisions will be made by your clinician.</p>

<p>Contact Information</p>

<p>If you have any questions or concerns about how your data is being handled, or if you wish to exercise any of your rights, please contact:</p>

<p>[Practice Name]</p>
<p>[Clinician’s Name]</p>
<p>[Contact Details]</p>

<p>Patient Acknowledgement</p>

<p>I have read and understood the information provided regarding the collection, storage, and use of my data in conjunction with the PsychScribe AI platform. I consent to the recording and collection of my data as described. I understand that I may withdraw my consent at any time by contacting my clinician.</p>

<p>Patient Name: ______________________________________</p>
<p>Date: _____________________________________________</p>
<p>Signature: __________________________________________</p>


                </div>
            </section>
            <Footer />
        </>
    )
}