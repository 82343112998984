import arrorRight from "../../assets/img/arrow-right.svg"
import userIcon from "../../assets/img/user_icon.svg"
import patient_file_management_icon from "../../assets/img/patient_file_management_icon.svg";
import from_our_desk_img from "../../assets/img/from_our_desk_img.jpg";
import feature_bg from "../../assets/img/feature_bg.svg";
import feature_1 from "../../assets/img/feature_1.svg";
import feature_2 from "../../assets/img/feature_2.svg";
import feature_3 from "../../assets/img/feature_3.svg";
import regular from "../../assets/img/regular.svg";
import silver from "../../assets/img/silver.svg";
import gold from "../../assets/img/gold.svg";
import plan_group from "../../assets/img/plan_group.jpg";
import discover_img from "../../assets/img/discover_img.jpg";
import hero from "../../assets/img/hero.jpg";
import tetimonial_block from "../../assets/img/tetimonial_block.png";
import quote from "../../assets/img/quote-icon.svg";
import drjane from "../../assets/img/dr.jane.jpg";

import React from 'react';
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const SliderSettings = {
  dots: true,              // Show dots navigation
  infinite: true,          // Infinite loop
  speed: 500,              // Transition speed (ms)
  slidesToShow: 1,         // Number of slides to show at once
  slidesToScroll: 1,       // Number of slides to scroll at a time
  autoplay: true,          // Enable autoplay
  autoplaySpeed: 2000,     // Autoplay speed (ms)
  arrows: true,            // Show arrows for navigation
};


export const HomePage = () => {
  const accessToken = localStorage.getItem('accessToken');

  return (
    <>



      <section class="site_hero">
        <div class="site_container">
          <div class="site_row">
            <div class="txt_colm">
              <div class="txt_block">
                <h1>Intelligent Insights for a Healthier Tomorrow.</h1>
                <p>Experience a revolutionary way to manage patient files and generate comprehensive reports.</p>
                <div class="btn_row">
                  <Link to={'/#'} class="solid_cta cta_primary cta_with_icon"><span>Learn More</span> <img
                    src={arrorRight} alt="" /></Link>
                  {accessToken !== null ?
                    <Link to={'#'} className="outlined_cta cta_outline cta_with_icon"><img src={userIcon} alt="" /><span>Profile</span><img
                      src={arrorRight} alt="" />
                    </Link>
                    :
                    <Link to={'/signup'} class="outlined_cta cta_outline cta_with_icon"><span>Sign Up</span> <img
                      src={arrorRight} alt="" /></Link>}
                </div>
              </div>
            </div>
            <div class="img_colm">
              <div class="img_block">
                <img src={hero} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="from_our_desk">
        <div class="site_container">
          <div class="site_row">
            <div class="txt_colm">
              <div class="txt_block">
                <span class="tag">From our desk</span>
                <h2>Streamline Your Clinical Practice with Our Platform</h2>
                <p>Our web application offers a range of features to help <strong>clinicians manage their practice
                  efficiently and effectively</strong>. From <strong>account creation to patient file management and
                    report
                    generation</strong>, our platform simplifies the entire process.</p>
                <div class="card_list">
                  <div class="list_item">
                    <img src={userIcon} alt="" />
                    <h4>Account Creation</h4>
                    <p>Create secure accounts, select your role, and choose your theoretical framework.</p>
                  </div>
                  <div class="list_item">
                    <img src={patient_file_management_icon} alt="" />
                    <h4>Patient File Management</h4>
                    <p>Effortlessly organize patient files, upload documents, and generate templates for reports.</p>
                  </div>
                </div>
                <div class="btn_row">
                  <Link to={'/#'} class="solid_cta cta_primary cta_with_icon"><span>Learn More</span> <img
                    src={arrorRight} alt="" /></Link>
                  {accessToken === null &&
                    <Link to={'/signup'} class="outlined_cta cta_outline cta_with_icon"><span>Sign Up</span> <img
                      src={arrorRight} alt="" /></Link>}
                </div>
              </div>
            </div>
            <div class="img_colm">
              <div class="img_block">
                <div class="block_inner">
                  <img src={from_our_desk_img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="feature_block">
        <img src={feature_bg} alt="" class="f_bg" />
        <div class="site_container">
          <div class="heading_block">
            <span class="tag">Features</span>
            <h2>Streamline Your Clinical Practice with Our Powerful Features</h2>
            <p>Our web application offers a range of features designed to enhance your clinical practice. From role
              selection to theoretical framework options, we provide the tools you need to deliver personalized and
              effective care.</p>
          </div>
          <div class="site_row">
            <div class="colm">
              <div class="feature_item">
                <div class="top">
                  <div class="icon_block">
                    <img src={feature_1} alt="" />
                  </div>
                  <h3>Choose Your Role and Theoretical Framework</h3>
                  <p>Select your role as a psychologist or psychiatrist and choose from various theoretical frameworks,
                    including Psychodynamic Model, Bio Psychosocial Model, Cognitive Behavior Therapy (CBT).</p>
                </div>
                <div class="bottom">
                  <a href=""><span>Learn More</span> <img src={arrorRight} alt="" /></a>
                </div>
              </div>
            </div>
            <div class="colm">
              <div class="feature_item">
                <div class="top">
                  <div class="icon_block">
                    <img src={feature_2} alt="" />
                  </div>
                  <h3>Efficient Diagnostic Tool Options</h3>
                  <p>Utilize our diagnostic tool options, such as DSM-5-TR or ICD-11, to accurately assess and diagnose your
                    patients.</p>
                </div>
                <div class="bottom">
                  <a href=""><span>Learn More</span> <img src={arrorRight} alt="" /></a>
                </div>
              </div>
            </div>
            <div class="colm">
              <div class="feature_item">
                <div class="top">
                  <div class="icon_block">
                    <img src={feature_3} alt="" />
                  </div>
                  <h3>Simplify Patient File Management</h3>
                  <p>Create and organize patient files effortlessly, with the ability to upload medical records and past
                    reports for easy access and reference.</p>
                </div>
                <div class="bottom">
                  <a href=""><span>Learn More</span> <img src={arrorRight} alt="" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonial_block">
        <div class="bg_layer">
          <img src={tetimonial_block} alt="" />
        </div>
        <div class="slider_layer">
          <div class="site_container">
            <div class="heading_block">
              <span class="tag">Testimonial</span>
              <h2>What the Clinicians say</h2>
            </div>
            <div class="testimonial_slider">
              <Slider {...SliderSettings}>
                <div class="slide_item">
                  <img src={quote} alt="" class="quote_icon" />
                  <p>The platform has revolutionized my practice. It's efficient, user-friendly, and provides accurate
                    diagnoses.</p>

                  <div class="img_avatar">
                    <div class="img_block">
                      <img src={drjane} alt="" />
                    </div>
                    <span class="title">Dr. Jane</span>
                    <span class="desg">Clinical Psychologist, ABC Clinic</span>
                  </div>
                </div>
                <div class="slide_item">
                  <img src={quote} alt="" class="quote_icon" />
                  <p>The platform has revolutionized my practice. It's efficient, user-friendly, and provides accurate
                    diagnoses.</p>

                  <div class="img_avatar">
                    <div class="img_block">
                      <img src={drjane} alt="" />
                    </div>
                    <span class="title">Dr. Jane</span>
                    <span class="desg">Clinical Psychologist, ABC Clinic</span>
                  </div>
                </div>
                <div class="slide_item">
                  <img src={quote} alt="" class="quote_icon" />
                  <p>The platform has revolutionized my practice. It's efficient, user-friendly, and provides accurate
                    diagnoses.</p>

                  <div class="img_avatar">
                    <div class="img_block">
                      <img src={drjane} alt="" />
                    </div>
                    <span class="title">Dr. Jane</span>
                    <span class="desg">Clinical Psychologist, ABC Clinic</span>
                  </div>
                </div>
                <div class="slide_item">
                  <img src={quote} alt="" class="quote_icon" />
                  <p>The platform has revolutionized my practice. It's efficient, user-friendly, and provides accurate
                    diagnoses.</p>

                  <div class="img_avatar">
                    <div class="img_block">
                      <img src={drjane} alt="" />
                    </div>
                    <span class="title">Dr. Jane</span>
                    <span class="desg">Clinical Psychologist, ABC Clinic</span>
                  </div>
                </div>
                <div class="slide_item">
                  <img src={quote} alt="" class="quote_icon" />
                  <p>The platform has revolutionized my practice. It's efficient, user-friendly, and provides accurate
                    diagnoses.</p>

                  <div class="img_avatar">
                    <div class="img_block">
                      <img src={drjane} alt="" />
                    </div>
                    <span class="title">Dr. Jane</span>
                    <span class="desg">Clinical Psychologist, ABC Clinic</span>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section class="pricing_plan_block">
        <div class="site_container">
          <div class="site_row">
            <div class="left_colm">
              <div class="heading_block">
                <span class="tag">Packages</span>
                <h2>Pricing Plans</h2>
                <p>Choose the subscription tier that suits your needs and budget.</p>
              </div>

              <ul class="plan_list">
                <li>
                  <div class="icon_block">
                    <img src={regular} alt="" />
                  </div>
                  <div class="txts">
                    <p>Regular Plan</p>
                    <span>Access to <strong>10 reports</strong> per month.</span>
                  </div>
                </li>
                <li>
                  <div class="icon_block">
                    <img src={silver} alt="" />
                  </div>
                  <div class="txts">
                    <p>Silver Plan</p>
                    <span>Access to <strong>30 reports</strong> per month.</span>
                  </div>
                </li>
                <li>
                  <div class="icon_block">
                    <img src={gold} alt="" />
                  </div>
                  <div class="txts">
                    <p>Gold Plan</p>
                    <span>Access to <strong>50 reports</strong> per month.</span>
                  </div>
                </li>
              </ul>
              <div class="btn_row">
                <Link to={'/subscription'} class="solid_cta cta_primary cta_with_icon"><span>View All Plans</span> <img
                  src={arrorRight} alt="" /></Link>
              </div>
            </div>
            <div class="plan_colm">
              <div class="bg_layer">
                <img src={plan_group} alt="" />
              </div>
              <div class="pkg_item">
                <div class="pkg_header">
                  <h5>Regular Plan</h5>
                  <div class="plan_price">
                    <h6><sup>$</sup>19<sub>/mo</sub></h6>
                    <span>Limited Access</span>
                  </div>
                </div>
                <div class="pkg_body">
                  <span class="txt">Includes</span>
                  <ul>
                    <li>Access to basic mental health assessments (e.g., anxiety, stress, and mood tracking)</li>
                    <li>Weekly AI-generated mental health reports</li>
                    <li>Personalized recommendations for self-care activities</li>
                    <li>Limited access to mindfulness exercises and breathing techniques</li>
                    <li>Community forum access for peer support</li>
                    <li>Standard customer support</li>
                  </ul>

                  <Link to={'/subscription'} class="get_started_btn"><span>View All Plans</span> <img src={arrorRight}
                    alt="" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="discover_block">
        <div class="bg_layer">
          <div class="img_block">
            <img src={discover_img} alt="" />
          </div>
        </div>
        <div class="txt_layer">
          <div class="site_container">
            <div class="site_row">
              <div class="left_colm">
                <div class="txt_block">
                  <span class="tag">Discover Now</span>
                  <h4>Transform Your Clinical Practice</h4>
                </div>
              </div>
              <div class="right_colm">
                <div class="txt_block">
                  <p>Discover a powerful platform that streamlines your workflow and enhances patient care.</p>
                  <div class="btn_row">
                    <Link to={'/#'} class="solid_cta cta_primary cta_with_icon"><span>Learn More</span> <img
                      src={arrorRight} alt="" /></Link>
                    {accessToken === null &&
                      <Link to={'/signup'} class="outlined_cta cta_outline cta_with_icon"><span>Sign Up</span> <img
                        src={arrorRight} alt="" /></Link>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}