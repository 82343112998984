import { useLocation, useNavigate } from "react-router";
import { FAILURE_SUBSCRIPTION, SUCCESS_SUBSCRIPTION } from "../action/apiPath";
import { Footer } from "../components/Footer"
import Header from "../components/Header"
import { toast, ToastContainer } from "react-toastify";
import { CallWithAuth } from "../action/apiActions";
import { useEffect } from "react";


export const SubscriptionFailure=()=>{
    window.scrollTo(0, 0);

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");

  console.log(sessionId);

  const UpdateSuccess = async (e) => {
    if (e) {
      try {
        const body = {
          session_id: e,
        };
        const response = await CallWithAuth("PUT", FAILURE_SUBSCRIPTION, body);
        console.log(response);
        if (response.res.status == 200 || 201) {
          // setSubscriptionDetail(response.res.data.data)
          toast.success(response.res.data.message, {
            autoClose: 2000,
            position: "bottom-right",
          });

          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          toast.error(response.res.data.message, {
            autoClose: 2000,
            position: "bottom-right",
          });
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
      } catch (err) {
        toast.error(err, {
          autoClose: 2000,
          position: "bottom-right",
        });
      }
    }
  };

  useEffect(() => {
    UpdateSuccess(sessionId);
  }, [sessionId]);
    return(
        <>
       <Header/>
       <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Payment Failed</h1>
      </div>
        <Footer/>

        <ToastContainer />

        </>
    )
}