import React from 'react'
import Header from '../components/Header'
import { Footer } from '../components/Footer'
import PatientReportInformationPage from '../views/patientInformation/patientReportInformationPage'

const PatientReportInformation = () => {
    window.scrollTo(0, 0);

    return (
        <div>
            <Header />
            <PatientReportInformationPage />
            <Footer />
        </div>
    )
}

export default PatientReportInformation
