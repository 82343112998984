export const GET_INFO = "GET_INFO"
export const GET_INFO_HIT="GET_INFO_HIT"
export const GET_INFO_SUCCEEDED= 'GET_INFO_SUCCEEDED'
export const GET_INFO_FAILED='GET_INFO_FAILED'



export const GET_SIGNUP = "GET_SIGNUP"
export const GET_SIGNUP_HIT="GET_SIGNUP_HIT"
export const GET_SIGNUP_SUCCEEDED= 'GET_SIGNUP_SUCCEEDED'
export const GET_SIGNUP_FAILED='GET_SIGNUP_FAILED'


export const LOGOUT='LOGOUT'

export const CREATE_ACCOUNT='CREATE_ACCOUNT'
export const GET_CREATE_ACCOUNT_HIT= 'GET_CREATE_ACCOUNT_HIT'
export const GET_CREATE_ACCOUNT_SUCCESSED= 'GET_CREATE_ACCOUNT_SUCCESSED'
export const GET_CREATE_ACCOUNT_FAILED= 'GET_CREATE_ACCOUNT_FAILED'


export const MANAGE_PATIENT='MANAGE_PATIENT'
export const GET_MANAGE_PATIENT_HIT= 'GET_MANAGE_PATIENT_HIT'
export const GET_MANAGE_PATIENT_SUCCESSED= 'GET_MANAGE_PATIENT_SUCCESSED'
export const GET_MANAGE_PATIENT_FAILED= 'GET_CREATE_ACCOUNT_FAILED'

export const PATIENT_INFORMATION = 'PATIENT_INFORMATION'
export const GET_PATIENT_INFORMATION_HIT = 'GET_PATIENT_INFORMATION_HIT'
export const GET_PATIENT_INFORMATION_SUCCESSED = 'GET_PATIENT_INFORMATION_SUCCESSED'
export const GET_PATIENT_INFORMATION_FAILED = 'GET_PATIENT_INFORMATION_FAILED'

export const REPORT_TYPE = 'REPORT_TYPE'
export const GET_REPORT_TYPE_HIT = 'GET_REPORT_TYPE_HIT'
export const GET_REPORT_TYPE_SUCCESSED = 'GET_REPORT_TYPE_SUCCESSED'
export const GET_REPORT_TYPE_FAILED = 'GET_REPORT_TYPE_FAILED'

// export const PATIENT_MEDICINE_RECOMMENDATION = 'PATIENT_MEDICINE_RECOMMENDATION'
// export const GET_PATIENT_MEDICINE_RECOMMENDATION_HIT = 'GET_GET_PATIENT_MEDICINE_RECOMMENDATION_HIT'
// export const GET_PATIENT_MEDICINE_RECOMMENDATION_SUCCESSED = 'GET_PATIENT_MEDICINE_RECOMMENDATION_SUCCESSED'
// export const GET_PATIENT_MEDICINE_RECOMMENDATION_FAILED = 'GET_PATIENT_MEDICINE_RECOMMENDATION_FAILED'

// export const PATIENT_REPORT = 'PATIENT_REPORT'
// export const GET_PATIENT_REPORT_HIT = 'GET_PATIENT_REPORT_HIT'
// export const GET_PATIENT_REPORT_SUCCESSED = 'GET_PATIENT_REPORT_SUCCESSED'
// export const GET_PATIENT_REPORT_FAILED = 'GET_PATIENT_REPORT_FAILED'