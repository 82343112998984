import { Link } from "react-router-dom"
import footer_logo from "../assets/img/footer_logo.svg"

export const Footer = () => {
    return (
        <>
            <footer class="site_footer">
                <div class="top_part">
                    <div class="site_container">
                        <div class="site_row">
                            <div class="logo_colm">
                                <img src={footer_logo} alt="" />
                            </div>
                            <div class="footer_menu">
                                <ul>
                                    <li><a href="">About Us</a></li>
                                    <li><a href="">Services</a></li>
                                    <li><a href="">Contact Us</a></li>
                                    <li><a href="">FAQ</a></li>
                                </ul>
                            </div>
                            <div class="social_icon">
                                <ul>
                                    <li><a href=""><i class="fa-brands fa-facebook-f"></i></a></li>
                                    <li><a href=""><i class="fa-brands fa-linkedin-in"></i></a></li>
                                    <li><a href=""><i class="fa-brands fa-youtube"></i></a></li>
                                    <li><a href=""><i class="fa-brands fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom_part">
                    <div class="site_container">
                        <div class="site_row">
                            <p>© 2024. All rights reserved.</p>
                            <Link to={'/privacy-policy'}>Privacy Policy</Link>
                            <Link to={'/terms-condition'}>Terms and Condition</Link>
                            <a href="">Cookie Policy</a>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}