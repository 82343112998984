// import React, { useState } from "react";

// const AssessmentSection = ({ metadata }) => {
//   // Clone the metadata to allow editing while keeping the original structure intact
//   const [editableMetadata, setEditableMetadata] = useState([...metadata]);

//   // Handle text input changes
//   const handleInputChange = (index, key, value) => {
//     setEditableMetadata((prevState) => {
//       const newState = [...prevState];
//       newState[index] = { ...newState[index], [key]: value }; // Update the specific assessment object
//       return newState;
//     });
//   };

//   // Render fields for each assessment
//   const renderFields = () => {
//     return editableMetadata.map((item, index) => (
//       <div key={index}>
//         <h4>Assessment {index + 1}</h4>
//         <div>
//           <label>Source</label>
//           <input
//             type="text"
//             value={item.source}
//             onChange={(e) =>
//               handleInputChange(index, "source", e.target.value)
//             }
//           />
//         </div>
//         <div>
//           <label>Date</label>
//           <input
//             type="date"
//             value={item.date.split("/").reverse().join("-")} // Convert to 'yyyy-mm-dd'
//             onChange={(e) => {
//               const newDate = e.target.value.split("-").reverse().join("/");
//               handleInputChange(index, "date", newDate);
//             }}
//           />
//         </div>
//       </div>
//     ));
//   };

//   return (
//     <form>
//       <h2>Assessment Metadata</h2>
//       {renderFields()}
//       <pre>{JSON.stringify(editableMetadata, null, 2)}</pre> {/* Display changes */}
//     </form>
//   );
// };

// // Export the AssessmentSection as the default export
// export default AssessmentSection;





// import React, { useState } from "react";

// const AssessmentSection = ({ metadata }) => {
//   // Clone the metadata to allow editing while keeping the original structure intact
//   const [editableMetadata, setEditableMetadata] = useState([...metadata]);

//   // Handle text input changes
//   const handleInputChange = (index, key, value) => {
//     setEditableMetadata((prevState) => {
//       const newState = [...prevState];
//       newState[index] = { ...newState[index], [key]: value }; // Update the specific assessment object
//       return newState;
//     });
//   };

//   return (
//     <div>
//       <h2>Assessment Metadata</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Source</th>
//             <th>Date</th>
//           </tr>
//         </thead>
//         <tbody>
//           {editableMetadata.map((item, index) => (
//             <tr key={index}>
//               <td>
//                 <input
//                   type="text"
//                   value={item.source}
//                   onChange={(e) =>
//                     handleInputChange(index, "source", e.target.value)
//                   }
//                 />
//               </td>
//               <td>
//                 <input
//                   type="date"
//                   value={item.date.split("/").reverse().join("-")} // Convert to 'yyyy-mm-dd'
//                   onChange={(e) => {
//                     const newDate = e.target.value.split("-").reverse().join("/");
//                     handleInputChange(index, "date", newDate);
//                   }}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <pre>{JSON.stringify(editableMetadata, null, 2)}</pre> {/* Display changes */}
//     </div>
//   );
// };

// // Export the AssessmentSection as the default export
// export default AssessmentSection;





import React, { useState } from "react";

const AssessmentSection = ({ metadata }) => {
  // Clone the metadata to allow editing while keeping the original structure intact
  const [editableMetadata, setEditableMetadata] = useState([...metadata]);

  // Handle text input changes
  const handleInputChange = (index, key, value) => {
    setEditableMetadata((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [key]: value }; // Update the specific assessment object
      return newState;
    });
  };

  // Get dynamic headers from the first assessment object
  const headers = Object.keys(editableMetadata[0] || {});

  return (
    <div className="foralign max-60">
      <div className="site_container">
        <h2>Assessment</h2>
        <table>
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header.charAt(0).toUpperCase() + header.slice(1)}</th> // Capitalize the first letter
              ))}
            </tr>
          </thead>
          <tbody>
            {editableMetadata.map((item, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={header}>
                    <input
                      type={header === "date" ? "date" : "text"} // Set input type based on the header
                      value={header === "date" ? item.date.split("/").reverse().join("-") : item[header]}
                      onChange={(e) => {
                        const value = header === "date" ? e.target.value.split("-").reverse().join("/") : e.target.value;
                        handleInputChange(index, header, value);
                      }}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* <pre>{JSON.stringify(editableMetadata, null, 2)}</pre> */}
      </div>
    </div>
  );
};

// Export the AssessmentSection as the default export
export default AssessmentSection;
