import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Common toast function
const showToast = (type, message) => {
    const options = {
        autoClose: 2000,
        position: "bottom-right",
        toastId: "unique-toast", // Same toastId to prevent multiple toasts
    };

    switch (type) {
        case "success":
            toast.success(message, options);
            break;
        case "error":
            toast.error(message, options);
            break;
        case "info":
            toast.info(message, options);
            break;
        case "warning":
            toast.warning(message, options);
            break;
        default:
            toast(message, options);
    }
};

export { showToast, ToastContainer };
