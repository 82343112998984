import "../loginSignup/loginSignup.scss";
import signup_bg from "../../assets/img/login_bg.jpg";
import logo from "../../assets/img/logo.svg";
import password_show from "../../assets/img/password_show.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginApp } from "../../redux/action";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Loading from 'react-fullscreen-loading';
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";



const Login = () => {
  const subscription_id = localStorage.getItem("subscription_id")
  const info = JSON.parse(localStorage.getItem("info"));
  const navigate = useNavigate()
  // __________ selector from redux __________
  const selector = useSelector((state) => state.GET_INFO_REDUCER)
  // __________ selector from redux __________


  // __________ state management __________
  const [consent, setConsent] = useState(false)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [constError, setConstError] = useState(null)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [loginDetail, setLoginDetail] = useState(
    {
      email: null,
      password: null
    }
  )
  // __________ state management __________


  // __________ Rememberme Function __________

  // useEffect(() => {
  //     console.log(Cookies.get("login"))
  //     const savedLoginData = JSON.parse(Cookies.get("login"));
  //     if (savedLoginData.consent) {
  //         setLoginDetail(prevState => ({
  //             ...prevState,
  //             email: savedLoginData.email,
  //             password: savedLoginData.password
  //         }));

  //         setConsent(savedLoginData.consent);
  //     }
  // }, []);

  useEffect(() => {
    const loginCookie = Cookies.get("login");

    if (loginCookie) {  // Check if the cookie exists
      try {
        const savedLoginData = JSON.parse(loginCookie);
        if (savedLoginData.consent) {
          setLoginDetail(prevState => ({
            ...prevState,
            email: savedLoginData.email,
            password: savedLoginData.password
          }));

          setConsent(savedLoginData.consent);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.log("No login cookie found");
    }
  }, []);

  // __________ Rememberme Function __________


  // __________ handleFunction __________

  const handleEmail = (e) => {
    setLoginDetail(prevState => ({
      ...prevState, // Spread the previous state to keep the password
      email: e.target.value  // Update the email
    }));
  }

  const handlePassword = (e) => {
    setLoginDetail(prevState => ({
      ...prevState, // Spread the previous state to keep the password
      password: e.target.value // Update the email
    }));
  }

  const handleLoginEffect = () => {
    if (selector.error == null) {
      if (selector.data != null) {
        toast.success(selector.message, {
          autoClose: 2000,
          position: "bottom-right",

        })
        setTimeout(() => {
          (subscription_id !== null && subscription_id !== "null")
            ?
            (info !== null && info !== "null")
              ?
              navigate("/")
              // navigate("/manage-patient")
              :
              navigate("/")
              // navigate("/create-account")
            :
            navigate("/subscription")
          // navigate("/subscription"); // Navigate after 2 seconds
        }, 1000);
      }
    } else {
      toast.error(selector.error, {
        autoClose: 2000,
        position: "bottom-right",
      })
    }
  }

  // __________ handleFunction __________


  // __________ Redux Action __________
  const dispatch = useDispatch()

  const handleSubmitFormLogin = (event) => {
    event.preventDefault();

    setEmailError("");
    setPasswordError("");
    setConstError("")

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!loginDetail.email) {
      setEmailError("* Email is required.");
      return;
    } else if (!emailRegex.test(loginDetail.email)) {
      setEmailError("* Invalid email format.");
      return;
    }


    // Password validation (for example, a minimum of 8 characters)
    if (!loginDetail.password) {
      setPasswordError("* Password is required.");
      return;
    } else if (loginDetail.password.length < 8) {
      setPasswordError("* Password must be at least 8 characters long.");
      return;
    }

    dispatch(loginApp({ ...loginDetail, consent }))
  }
  // __________ Redux Action __________



  useEffect(() => {
    handleLoginEffect()
  }, [selector])

  // console.log("selector", selector)

  return (
    <>
      {/* {selector.isLoading && <Loading loading background="rgba(236, 240, 241, 0.7)" loaderColor="#3498db" />} */}

      <section class="login_signup_wrapper">
        <div class="img_colm">
          <div class="back_btn" onClick={() => navigate('/')} >
            <i class="fa-solid fa-chevron-left"></i>
          </div>
          <div class="img_block">
            <img src={signup_bg} alt="" />
          </div>
        </div>
        <div class="form_colm">
          <div class="inner_block">
            <img src={logo} alt="" class="logo_img" />
            <h1>Login</h1>
            <p>Create an account or log in to manage your business.</p>

            <form onSubmit={handleSubmitFormLogin} class="form_block">
              <div class="form_field">
                <div class="input_grp">
                  <label for="">Email</label>
                  <input type="text" onChange={handleEmail} value={loginDetail.email} />
                </div>
                <span className="red_error">{emailError}</span>

              </div>
              <div class="form_field">
                <div class="input_grp">
                  <label for="">Password</label>
                  <div class="pass_field">
                    <input type={isPasswordVisible ? "text" : "password"} onChange={handlePassword} value={loginDetail.password} />
                    <span class={`show_hide_btn ${!isPasswordVisible ? 'hide' : ''}`} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                      <img src={password_show} alt="" />
                    </span>
                  </div>
                  <span className="red_error">{passwordError}</span>

                </div>
              </div>
              <div class="site_row">
                <div class="input_grp">
                  <input type="checkbox" id="consentCheckbox" checked={consent} onChange={(e) => setConsent(e.target.checked)} />
                  <label for="" htmlFor="consentCheckbox">Remember Me</label>
                </div>
                <a href="" class="forget_pass_link">Forgot Password?</a>
              </div>


              <div class="btn_block">
                <button type="submit">Login</button>
              </div>
              <p>Don’t have any account? <Link to={'/signup'}> &nbsp; Sign Up</Link></p>
            </form>
          </div>
        </div>
      </section>

      <ToastContainer />
    </>
  )
}

export default Login