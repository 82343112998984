import { Footer } from "../components/Footer"
import Header from "../components/Header"

export const Privacy = () => {

    window.scrollTo(0, 0);
    return (
        <>
            <Header />
            <section className="csm_page_wrapper">
                <div className="site_container">
              <h1>  Consent Form for Uploading De-Identified Reports to PsychScribe AI Platform</h1>

<h3>Introduction:</h3>
<p>As part of your engagement with the PsychScribe AI platform, you may upload de-identified patient reports to assist in the training of AI models. These models are used to learn report-writing styles, tone, and formats to enhance the accuracy of automated report generation and improve clinical support tools.
</p>

<div>
<p>This consent form ensures that you, as a clinician, are aware of your legal and ethical responsibilities regarding the use of de-identified data across various jurisdictions, including Australia, the UK, Europe, and the United States.</p>

<p>Acknowledgement of Legal and Ethical Responsibilities</p>

<p>By signing this form, you acknowledge that you are aware of and agree to the following responsibilities:</p>

<p>1. Compliance with Data Privacy Laws</p>

<p>You understand that, even when patient data is de-identified, you must comply with relevant data privacy laws in the jurisdiction where you practice, including but not limited to:</p>

<p>Australia:</p>
<p>	•	The Australian Privacy Act 1988 and Australian Privacy Principles (APPs).</p>


<p>United Kingdom:</p>
<p>	•	The UK Data Protection Act 2018 and General Data Protection Regulation (GDPR).</p>

    <p>Europe:</p>
    <p>	•	The General Data Protection Regulation (GDPR).</p>

    <p>United States:</p>
    <p>	•	The Health Insurance Portability and Accountability Act (HIPAA).</p>

    <p>You acknowledge that de-identified reports must not contain any information that could lead to the re-identification of the individual and that you are responsible for ensuring all identifying data (e.g., names, contact information, addresses, dates of birth) have been thoroughly removed prior to uploading.</p>

<hr/>
    <p>2. Informed Consent from Patients</p>

	<p>•	While de-identified data may be used for the training of the PsychScribe AI model, you understand that it is best practice to inform patients, where possible, about the use of their anonymised data for AI training.</p>
	<p>•	You agree to obtain informed consent from patients to use their data for secondary purposes, including AI model training, wherever applicable and feasible.</p>
    <hr/>
<p>3. Confidentiality and Security</p>

<p>•	You are aware that de-identified patient reports will be uploaded into an encrypted and secure database where the information will be processed solely for the purpose of training AI models within PsychScribe.</p>
<p>•	You confirm that all uploaded data has been properly anonymised and meets the legal and ethical standards of data minimisation, ensuring that only necessary information is uploaded for the intended purpose.</p>
<hr/>
<p>4. Ethical Responsibilities </p>

<p>You acknowledge that, as a practicing psychologist or psychiatrist, you are bound by the ethical codes of your professional body, including:</p>

<p>Australia:</p>
<p>•	The Australian Psychological Society (APS) Code of Ethics or equivalent professional guidelines.</p>

    <p>United Kingdom:</p>
	<p>•	The British Psychological Society (BPS) Code of Ethics and Conduct or equivalent professional guidelines.</p>

    <p>Europe:</p>
	<p>•	The European Federation of Psychologists’ Associations (EFPA) Meta-Code of Ethics or equivalent professional guidelines.</p>

    <p>United States:</p>
	<p>•	The American Psychological Association (APA) Code of Ethics or equivalent professional guidelines.</p>

    <p>You understand that, in line with these ethical obligations, you must ensure that the use of de-identified reports serves the best interest of patients and promotes beneficence without causing harm to the individual.</p>


    <hr/>
    <p>5. Data Protection Impact Assessment (DPIA)</p>

    <p>For jurisdictions where it applies (e.g., under GDPR), you are aware that a Data Protection Impact Assessment (DPIA) may be required before engaging in data processing activities involving de-identified data, especially for high-risk processing like AI training.</p>
    <hr/>
    <p>6. Accountability and Transparency</p>

    <p>You acknowledge your responsibility to ensure the transparency of data use and processing practices, including informing any relevant parties (such as patients, regulatory bodies, or institutions) about the nature and purpose of data collection and AI training.</p>

    <hr/>

    <p>Agreement</p>

    <p>By signing below, you agree to the terms and conditions outlined in this document and confirm that you have taken appropriate steps to comply with the legal and ethical requirements associated with uploading de-identified reports to the PsychScribe platform.</p>

<p>You acknowledge that you are responsible for ensuring that any de-identified patient data you upload is in full compliance with the relevant data protection and privacy regulations in your jurisdiction.</p>

<p>Clinician’s Name: ______________________________________</p>
<p>Professional Title (Psychologist/Psychiatrist): ______________________________________</p>
<p>Country of Practice: ______________________________________</p>
<p>Date: ________________________________________________</p>
<p>Signature: ____________________________________________</p>

<p>Contact Information for Queries:</p>

<p>If you have any questions regarding this consent form or your legal and ethical responsibilities, please contact:</p>
<p>
PsychScribe Support Team</p>
<p>Email: [support@psychscribe.com]</p>
<p>Phone: [+1-XXX-XXX-XXXX]</p>
</div>
                </div>
            </section>
            <Footer />
        </>
    )
}