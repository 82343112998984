import React from 'react'
import GuestLayout from '../layout/guestLayout'

export default function ContactUs() {
  return (
    <GuestLayout>
      <section class="from_our_desk">
        <div class="site_container">
          <div class="site_row">
            <div class="txt_colm">
              <div class="txt_block">
                <h1>The Evolution of Medicine: A Journey Through Time</h1>
                <p>Medicine has come a long way from its ancient roots to the highly advanced science we know today. This article explores the evolution of medicine, highlighting key milestones, innovations, and the future of healthcare.


                  The fall of the Roman Empire led to a stagnation in medical advancements in Europe, while the Islamic Golden Age saw significant progress in medicine. Scholars like Avicenna wrote influential texts such as "The Canon of Medicine," which synthesized knowledge from various cultures.

                  The Renaissance sparked a revival in scientific inquiry. Anatomists like Andreas Vesalius challenged Galenic principles through detailed human dissections, laying the groundwork for modern anatomy.</p>


                <h1>The Age of Enlightenment and Modern Medicine</h1>

              </div>
            </div>
            <div class="img_colm">
              <div class="img_block">
                <div class="block_inner">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </GuestLayout>
  )
}
