import React, { useEffect, useState } from "react";
import rightArrow from "../../assets/img/arrow-right.svg";
import tooltip_info_icon from "../../assets/img/tooltip_info_icon.svg";
import upload_icoon from "../../assets/img/upload_icoon.svg";
import { Link, useNavigate } from "react-router-dom";
import { CallWithAuth } from "../../action/apiActions";
import { DIAGONASTIC_TOOL, PRACTICE_FRAMEWORK } from "../../action/apiPath";
import { useDispatch, useSelector } from "react-redux";
import { accountCreate } from "../../redux/action";
import { ToastContainer, toast } from 'react-toastify';

const CreateAccountPage = () => {
  const subscription_id = localStorage.getItem("subscription_id")
  const dispatch = useDispatch()

  const [practiceFrameworks, setPracticeFrameworks] = useState([]);
  const [diagnostic_tool, setDiagnosticTool] = useState([]);

  const [createAccount, setCreateAccount] = useState({
    dr_role: null,
    practice_framework_id: null,
    diagnostic_tool_id: null,
    file: null,
  });

  const handleRoleChange = (e) => {
    const { name, value } = e.target;
    setCreateAccount({
      ...createAccount,
      [name]: value,
    });
  };

  const practiceFrameworksApiCall = async () => {
    try {
      const response = await CallWithAuth("GET", PRACTICE_FRAMEWORK);
      if (response.res.data && response.res.data.statusCode === 200) {
        setPracticeFrameworks(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching practice frameworks:", error);
    }
  };

  const diagonastictoolApiCall = async () => {
    try {
      const response = await CallWithAuth("GET", DIAGONASTIC_TOOL);
      if (response.res.data && response.res.data.statusCode === 200) {
        setDiagnosticTool(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching practice frameworks:", error);
    }
  };

  useEffect(() => {
    practiceFrameworksApiCall();
    diagonastictoolApiCall();
  }, []);

  const handleFrameworkChange = (e) => {
    setCreateAccount({
      ...createAccount,
      practice_framework_id: e.target.value,
    });
  };

  const handleDiagnosticToolChange = (e) => {
    setCreateAccount({
      ...createAccount,
      diagnostic_tool_id: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files;
    setCreateAccount({
      ...createAccount,
      file: file,
    });
  };

  const handleSubmitCreate = () => {
    if(!createAccount.dr_role){
      return toast.error("Role is required.", {
        autoClose: 2000,
        position: "bottom-right",
      })
    }
    if(!createAccount.practice_framework_id){
      return toast.error("Practice Framework is required.", {
        autoClose: 2000,
        position: "bottom-right",
      })
    }
    if(!createAccount.diagnostic_tool_id){
      return toast.error("Provisional Diagnostic Tool is required.", {
        autoClose: 2000,
        position: "bottom-right",
      })
    }
    if(!createAccount.file){
      return toast.error("De-identified Reports is required.", {
        autoClose: 2000,
        position: "bottom-right",
      })
    }
    dispatch(accountCreate(createAccount))
  }

  const selector = useSelector((state) => state.GET_CREATE_ACCOUNT_REDUCER)
  const navigate = useNavigate()

  const handleCreateAccountEffect = () => {
    if (selector.error == null) {
      if (selector.data != null) {
        toast.success(selector.message, {
          autoClose: 2000,
          position: "bottom-right",
        })
        if(subscription_id !== null) {
          setTimeout(() => {
            navigate("/manage-patient")
          }, 1000)
        } else {
          setTimeout(() => {
            navigate("/subscription"); // Navigate after 2 seconds
          }, 1000);
        }
      }
      toast.success(selector.message, {
        autoClose: 2000,
        position: "bottom-right",
      })
    } else {
      toast.error(selector.error, {
        autoClose: 2000,
        position: "bottom-right",
      })
    }
  }

  useEffect(() => {
    handleCreateAccountEffect()
  }, [selector])

  // console.log("createAccount", createAccount);

  // console.log("selector", selector)


  return (
    <div>
      <section class="create_account_block">
        <div class="site_container">
          <div class="step_block">
            <span class="active"></span>
            <span></span>
          </div>
          <div class="btn_row">
            <Link to={"/"} class="outlined_cta cta_outline cta_with_icon">
              <span>Close</span>{" "}
            </Link>
            <a onClick={handleSubmitCreate} class="solid_cta cta_primary cta_with_icon">
              <span>Continue</span> <img src={rightArrow} alt="" />
            </a>
          </div>
          <div class="heading_block">
            <span class="tag">Account Setup</span>
            <h1>Create your Account</h1>
            <p>
              Choose your role and practice framework and create your account
            </p>
          </div>
          <div class="create_account_form">
            <div class="field_block">
              <label for="">Select Role</label>
              <div class="input_check_grp">
                <div class="item">
                  <input
                    type="radio"
                    name="dr_role"
                    value="psychiatrist"
                    checked={createAccount.dr_role === "psychiatrist"}
                    onChange={handleRoleChange}
                  />
                  <span>psychiatrist </span>
                </div>
                <div class="item">
                  <input
                    type="radio"
                    name="dr_role"
                    value="psychologist"
                    checked={createAccount.dr_role === "psychologist"}
                    onChange={handleRoleChange}
                  />
                  <span>psychologist </span>
                </div>
              </div>
            </div>
            <div className="field_block">
              <label>Select Practice Framework</label>
              <select
                name="practice_framework"
                id="practice_framework"
                onChange={handleFrameworkChange}
              >
                <option value="">Select Practice Framework</option>
                {practiceFrameworks?.map((framework) => (
                  <option key={framework.id} value={framework.id}>
                    {framework.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="field_block">
              <label>Select Provisional Diagnostic Tool</label>
              <select
                name="diagnostic_tool"
                id="diagnostic_tool"
                onChange={handleDiagnosticToolChange}
              >
                <option value="">Select Diagnostic Tool</option>
                {diagnostic_tool?.map((tool) => (
                  <option key={tool.id} value={tool.id}>
                    {tool.name}
                  </option>
                ))}
              </select>
            </div>

            <div class="field_block">
              <div class="upload_field">
                <div class="title">
                  <label for="">Upload De-identified Reports</label>{" "}
                  <a
                    href=""
                    onClick={(e) => e.preventDefault()}
                    data-bs-toggle="tooltip"
                    data-bs-title=" Allow our advanced AI models to learn how you like your reports to be written in your preferred tone and format. "
                  >
                    <img src={tooltip_info_icon} alt="" />{" "}
                  </a>{" "}
                </div>

                <div className="upload_ui">
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleFileChange}
                    accept=".doc, .docx, .pdf"
                  />
                  <div className="ui_inner">
                    <img src={upload_icoon} alt="Upload Icon" />
                    <h5>Upload</h5>
                    <p>
                      <span>Browse</span> to upload the file
                    </p>
                  </div>
                </div>

                {createAccount.file ? (
                  <p class="sm_txt">
                    <strong>{createAccount.file[0].name}</strong>
                  </p>
                ) : (
                  <p class="sm_txt">
                    Supported file formats are <strong>.docs, .pdf.</strong>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />

    </div>
  );
};

export default CreateAccountPage;
