import React, { useState, useRef, useEffect } from "react";
import Utils from "../../util/util";

const MetadataSection = ({ metadata }) => {
  // Clone the metadata to allow editing while keeping the original structure intact
  const [editableMetadata, setEditableMetadata] = useState({ ...metadata });
  // console.log("metadata==========>", metadata)

  // Handle text input changes
  const handleInputChange = (key, value, parentKey = null) => {
    setEditableMetadata((prevState) => {
      const newState = { ...prevState };

      if (parentKey) {
        const keys = parentKey.split('.'); // Split the parentKey to access nested keys
        let current = newState;

        keys.forEach((k, index) => {
          // Ensure current is an object
          if (!current[k]) {
            current[k] = {}; // Initialize if undefined
          }
          // If at the last key, set the value
          if (index === keys.length - 1) {
            // Handle case for arrays
            if (Array.isArray(current[k])) {
              current[k][key] = value; // Update value in array based on index
            } else {
              current[k] = value; // Set the value directly for non-array
            }
          } else {
            current = current[k]; // Navigate deeper
          }
        });
      } else {
        newState[key] = value; // Update top-level key
      }

      return newState;
    });
  };

  // Render dynamic fields based on the type of value
  const renderFields = (data, parentKey = '') => {
    console.log({ data, parentKey })
    return Object.keys(data).map((key) => {
      const value = data[key];
      const isNestedObject = typeof value === 'object' && !Array.isArray(value);
      const isArray = Array.isArray(value);
      const isLongValue = typeof value === 'string' && value.length > 50; // Set your preferred length limit here

      const fullKey = parentKey ? `${parentKey}.${key}` : key; // Build the full key path

      if (isNestedObject) {
        return (
          <div key={fullKey} className="xyz">
            <h4 style={{ textTransform: "capitalize", fontSize: "24px" }}>{Utils.convertSnakeToSpace(key)}</h4>
            {renderFields(value, fullKey)} {/* Recursive rendering for nested objects */}
          </div>
        );
      } else if (isArray) {
        return (
          <div key={fullKey} className="form-group">
            <label style={{ textTransform: "capitalize", fontSize: "24px" }}>{Utils.convertSnakeToSpace(key)}</label>
            {value.map((item, index) => (
              isLongValue ? (
                <TextareaAutosize
                  key={`${fullKey}-${index}`} // Unique key for each array item
                  value={item}
                  onChange={(e) =>
                    handleInputChange(index, e.target.value, fullKey) // Pass full key path and index
                  }
                />
              ) : (
                <input
                  key={`${fullKey}-${index}`}
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleInputChange(index, e.target.value, fullKey) // Pass full key path and index
                  }
                />
              )
            ))}
          </div>
        );
      } else {
        return (
          <div key={fullKey} className="form-group">
            {/* <label style={{textTransform: "capitalize"}}>{Utils.convertSnakeToSpace(key)}</label> */}
            {isLongValue ? (
              <TextareaAutosize
                value={value}
                onChange={(e) =>
                  handleInputChange(key, e.target.value, fullKey) // Pass full key path
                }
              />
            ) : (
              <input
                type="text"
                value={value}
                onChange={(e) =>
                  handleInputChange(key, e.target.value, fullKey) // Pass full key path
                }
              />
            )}
          </div>
        );
      }
    });
  };

  return (
    <div className="foralign max-60">
      <div class="site_container">
        <form>
          <h2>{editableMetadata.report_type}</h2>
          {renderFields(editableMetadata)}
          {/* <pre>{JSON.stringify(editableMetadata, null, 2)}</pre> */}
        </form>
      </div>
    </div>
  );
};

// TextareaAutosize component for dynamic height adjustment
const TextareaAutosize = React.forwardRef((props, ref) => {
  const { value, onChange, style, ...rest } = props;

  // Create a ref to the textarea
  const textareaRef = useRef(null);

  // Use effect to adjust height
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height to auto to get the scrollHeight
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scrollHeight
    }
  }, [value]); // Only update when value changes

  return (
    // <textarea
    //   ref={textareaRef}
    //   value={value}
    //   onChange={onChange}
    //   style={{
    //     width: '100%', // Full width
    //     minHeight: '70px', // Minimum height
    //     resize: 'none', // Disable manual resize
    //   }}
    //   {...rest}
    // />

    <div style={{
      fontSize: "14px",
      padding: "15px",
      background: "#ffff0073",
      margin: "5px",
      borderRadius: "10px"
    }}>
      <span>
        {value}
      </span>
    </div>

  );
});


// Export the MetadataSection as the default export
export default MetadataSection;

