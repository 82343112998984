import React, { useEffect, useRef, useState } from "react";
import mic from "../assets/img/mic.png";
import { useDispatch, useSelector } from "react-redux";
import { getReportType } from "../redux/action";
import { useLocation, useParams } from "react-router";
import {
  UPLOAD_CLICNICAL_INTAKE_DOC,
  UPLOAD_CLICNICAL_INTAKE_FILE,
  UPLOAD_CLICNICAL_INTAKE_SUBMIT,
} from "../action/apiPath";
import { CallWithAuth } from "../action/apiActions";

const fieldConfig = {
  referral_info: {
    label: "Referral Information:",
    placeholder:
      "Who referred you, why, and when? (e.g., GP, self, diagnostic assessment)",
  },
  present_concern: {
    label: "Presenting Concerns:",
    placeholder:
      "What is the main concern and any additional concerns? Duration? How is this impacting daily life?",
  },
  medical_history: {
    label: "Medical and Psychiatric History:",
    placeholder:
      "Current diagnoses (medical and mental health), medications, allergies? Past conditions or surgeries?",
  },
  developmental_history: {
    label: "Developmental History:",
    placeholder:
      "Pregnancy/birth complications? Early developmental milestones?",
  },
  family_history: {
    label: "Family and Social History:",
    placeholder:
      "Family mental and medical health history? Social support systems?",
  },
  social_history: {
    label: "Educational/Occupational History:",
    placeholder:
      "Current education/occupation? Learning difficulties or accommodations?",
  },
  substance_use_history: {
    label: "Substance Use History:",
    placeholder: "Any history of substance use or misuse?",
  },
  legal_history: {
    label: "Legal History:",
    placeholder: "Any involvement with legal issues or convictions?",
  },
  cultural_context: {
    label: "Cultural and Social Context:",
    placeholder: "Cultural background, religious beliefs, languages spoken?",
  },
  phychosocial_trauma: {
    label: "Experience of Significant Psychosocial Trauma:",
    placeholder: "Details of any significant traumas and their impact?",
  },
  additional_info: {
    label: "Additional Information:",
    placeholder: "Any other relevant factors for your assessment?",
  },
};



const categoryStatic = [
  {
    "id": "6f1bf9f2-3f09-465b-9884-d657261687f5",
    "name": "Report Type A",
    "created_at": "2024-11-05T06:49:58.156Z",
    "updated_at": "2024-11-05T06:49:58.156Z"
  },
  {
    "id": "91081fbf-b1d2-4505-83e5-de95385588fd",
    "name": "Report Type B",
    "created_at": "2024-11-05T06:53:29.366Z",
    "updated_at": "2024-11-05T06:53:29.366Z"
  },
  {
    "id": "0433fd14-090c-4724-bc51-fcf35923819a",
    "name": "Report Type C",
    "created_at": "2024-11-05T06:53:33.298Z",
    "updated_at": "2024-11-05T06:53:33.298Z"
  }
]

export default function ClinicalIntakeForm() {
  const selector = useSelector((state) => state.CLINICAL_INTAKE_REDUCER);
  const dispatch = useDispatch();
  const [activeField, setActiveField] = useState(null);
  const [successfullSubmission, setSuccessfullSubmission] = useState(false);

  const [files, setFiles] = useState([
    { file: null, status: "not_uploaded", category: "" },
  ]);
  const [docs, setDocs] = useState([
    { doc: null, status: "not_uploaded", category: "" },
  ]);

  const [dropdownOptions] = useState(categoryStatic);

  const location = useLocation();
  const [token, setToken] = useState(null);

  // console.log("============>", files, docs)

  useEffect(() => {
    // Use URLSearchParams to get the token from the URL
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");

    if (tokenParam) {
      localStorage.setItem("accessToken", tokenParam);
      dispatch(getReportType());
    }
  }, [location]);

  const [formData, setFormData] = useState({
    referral_info: "",
    present_concern: "",
    medical_history: "",
    developmental_history: "",
    family_history: "",
    social_history: "",
    substance_use_history: "",
    legal_history: "",
    cultural_context: "",
    phychosocial_trauma: "",
    additional_info: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const allDocs = docs
      ?.filter((each) => each.status === "uploaded")
      ?.map((eachFile) => ({
        type: "doc",
        file: eachFile.doc,
        report_type_id: eachFile.category,
      }));
    const allFiles = files
      ?.filter((each) => each.status === "uploaded")
      ?.map((eachFile) => ({
        type: "audio",
        file: eachFile.file,
        report_type_id: eachFile.category,
      }));

    const alldata = {
      ...formData,
      files: [
        ...allDocs, ...allFiles
      ],
    };

    console.log(alldata);
    const response = await handleSubmitResponse(alldata)

    if (response?.res?.data?.statusCode === 200) {
      setSuccessfullSubmission(true)
    }
    // Add form submission logic here
  };

  // Initialize Speech Recognition

  const handleVoiceInput = (field) => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setFormData((prevData) => ({
        ...prevData,
        [field]: prevData[field] + " " + transcript,
      }));
    };

    recognition.onend = () => {
      setActiveField(null); // Reset active field when done
    };

    if (activeField === field) {
      recognition.stop();
      setActiveField(null);
    } else {
      if (activeField !== null) {
        recognition.stop(); // Stop previous recognition if any
      }
      recognition.start();
      setActiveField(field);
    }
  };

  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index].file = e.target.files[0];
    newFiles[index].status = "not_uploaded";
    setFiles(newFiles);
  };

  const handleCategoryChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index].category = e.target.value;
    setFiles(newFiles);
  };

  const handleUpload = async (index) => {
    const newFiles = [...files];
    const response = await handleUploadFile(newFiles[index].file);

    if (response?.res?.data?.statusCode === 200) {
      newFiles[index].status = "uploaded";
      newFiles[index].file = response?.res?.data?.data?.file_url;
    }
    setFiles(newFiles);
  };

  const addMoreFile = () => {
    setFiles([...files, { file: null, status: "not_uploaded", category: "" }]);
  };

  // Check if all files are uploaded
  const allFilesUploaded = files.every(
    (fileObj) => fileObj.status === "uploaded"
  );

  const handleDocsChange = (e, index) => {
    const newDocs = [...docs];
    // console.log("===========>", e.target.files)
    newDocs[index].doc = e.target.files[0];
    newDocs[index].status = "not_uploaded";
    setDocs(newDocs);
  };

  const handleCategoryChangeForDocs = (e, index) => {
    const newDocs = [...docs];
    newDocs[index].category = e.target.value;
    setDocs(newDocs);
  };

  const handleUploadForDocs = async (index) => {
    const newDocs = [...docs];

    const response = await handleUploadDocFile(newDocs[index].doc);

    if (response?.res?.data?.statusCode === 200) {
      newDocs[index].status = "uploaded";
      newDocs[index].doc = response?.res?.data?.data?.file_url;
    }
    setDocs(newDocs);
  };

  const addMoreFileForDocs = () => {
    setDocs([...docs, { doc: null, status: "not_uploaded", category: "" }]);
  };

  const handleUploadDocFile = async (file) => {
    try {
      const formdata = new FormData();
      formdata.append("file", file);

      const body = formdata;

      return await CallWithAuth("POST", UPLOAD_CLICNICAL_INTAKE_DOC, body);
    } catch (err) { }
  };

  const handleUploadFile = async (file) => {
    try {
      const formdata = new FormData();
      formdata.append("file", file);

      const body = formdata;

      return await CallWithAuth("POST", UPLOAD_CLICNICAL_INTAKE_FILE, body);
    } catch (err) { }
  };

  const handleSubmitResponse = async (payload) => {
    try {

      return await CallWithAuth("POST", UPLOAD_CLICNICAL_INTAKE_SUBMIT, payload);
    } catch (err) { }
  };
  // Check if all files are uploaded
  const allFilesUploadedForDocs = docs.every(
    (fileObj) => fileObj.status === "uploaded"
  );

  return (
    <div className="container">
      <section className="clinical-intake-form">
        {
          successfullSubmission ?
            <h1>Thanks For submitting your response.</h1>
            :
            <div className="container">
              <h3>PsychScribe: Clinical Intake Form </h3>

              <div className="form-cntn-box">
                <h5>Important Preface </h5>
                <p>
                  We understand that your clinician has obtained your consent for
                  your healthcare data to be collected by PsychScribe for the
                  purpose of diagnostic clinical reasoning and report writing
                  assistance. All the information you share in this form will be
                  treated with strict confidentiality, de-identified to protect your
                  privacy, and handled in accordance with the 1988 Privacy Act, the
                  Australian Privacy Principles (APP), and HIPAA guidelines.
                </p>
              </div>

              <form onSubmit={handleSubmit}>
                {Object.keys(formData).map((key) => (
                  <div className="form-group" key={key}>
                    <label>{fieldConfig[key].label}</label>
                    <textarea
                      className="form-control"
                      placeholder={fieldConfig[key].placeholder}
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      onClick={() => handleVoiceInput(key)}
                      className="microphone"
                    >
                      {activeField === key ? "Stop" : "Voice to Text"}
                      <img src={mic} alt="microphone" width="24" height="24" />
                    </button>
                  </div>
                ))}

                <div className="form-group col-info">
                  <label for="Collateral Information">Collateral Information</label>
                  <button
                    type="button"
                    className="details-tooltips"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-className="custom-tooltip"
                    data-bs-title="Please provide any collateral documents (e.g., school reports, medical records, psychological or allied health assessments) and upload relevant documentation below to assist with the assessment and report writing process)."
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </button>
                </div>

                <div className="d-flex">
                  {/* **********************DOCUMENT UPLOAD****************** */}
                  <div className="w-50 pr-4 customclass">
                    {docs.map((fileObj, index) => (
                      <div className="w-100">
                        <div key={index} className="form-group">
                          {fileObj.status === "uploaded" ? (
                            <span>
                              <i
                                className="fa-solid fa-file-alt"
                                style={{ marginRight: "8px" }}
                              ></i>
                              Document File uploaded
                            </span>
                          ) : (
                            <div className="upload-container">
                              <span className="upload-icon">
                                <i className="fa-solid fa-arrow-up"></i>
                              </span>
                              <input
                                type="file"
                                id={`doc-input-${index}`}
                                name={`doc-${index}`}
                                accept=".doc, .docx, .pdf"
                                onChange={(e) => handleDocsChange(e, index)}
                              />
                              <label
                                htmlFor={`doc-input-${index}`}
                                className="file-label"
                              >
                                Upload
                              </label>
                              {fileObj.doc ?
                                <p id="file-name" className="browse-file">
                                  Document Selected Please select Category and Upload doc.
                                </p>
                                :
                                <p id="file-name" className="browse-file">
                                  Browse <span>to upload the file</span>
                                </p>
                              }
                            </div>
                          )}

                          <div className="otherbuttons d-flex">
                            <select
                              value={fileObj.category}
                              onChange={(e) =>
                                handleCategoryChangeForDocs(e, index)
                              }
                              className="file-category-dropdown form-control"
                            >
                              <option value="">Select category</option>
                              {dropdownOptions.map((option, i) => (
                                <option key={i} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>

                            <button
                              type="button"
                              onClick={() => handleUploadForDocs(index)}
                              // disabled={!fileObj.doc || !fileObj.category || fileObj.status === 'uploaded'}
                              // style={{
                              //   color: (fileObj.doc || fileObj.category || fileObj.status !== 'uploaded') && "white",
                              //   backgroundColor: (fileObj.doc || fileObj.category || fileObj.status !== 'uploaded') && "#4d9d30",
                              // }}
                              className="upload-button form-control"
                            >
                              {fileObj.status === "uploaded"
                                ? "Uploaded"
                                : "Upload"}
                            </button>

                            {allFilesUploadedForDocs &&
                              index === docs.length - 1 && (
                                <button
                                  type="button"
                                  onClick={addMoreFileForDocs}
                                  className="add-more-button form-control"
                                >
                                  Add More Files
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* ********************************FILE UPLOAD******************** */}
                  <div className="w-50 pl-4 customclass">
                    {files.map((fileObj, index) => (
                      <div key={index} className="form-group">
                        {fileObj.status === "uploaded" ? (
                          <span>
                            <i
                              className="fa-solid fa-music"
                              style={{ marginRight: "8px" }}
                            ></i>
                            Audio file uploaded
                          </span>
                        ) : (
                          <div className="upload-container">
                            <span className="upload-icon">
                              <i className="fa-solid fa-arrow-up"></i>
                            </span>
                            <input
                              type="file"
                              id={`file-input-${index}`}
                              name={`file-${index}`}
                              accept=".mp3, .wav, .m4a"
                              onChange={(e) => handleFileChange(e, index)}
                            />
                            <label
                              htmlFor={`file-input-${index}`}
                              className="file-label"
                            >
                              Upload
                            </label>
                            {fileObj.file ?
                              <p id="file-name" className="browse-file">
                                Document Selected Please select Category and Upload doc.
                              </p>
                              :
                              <p id="file-name" className="browse-file">
                                Browse <span>to upload the file</span>
                              </p>
                            }
                          </div>
                        )}

                        <div className="otherbuttons d-flex">
                          {/* Dropdown for selecting category */}
                          <select
                            value={fileObj.category}
                            onChange={(e) => handleCategoryChange(e, index)}
                            className="file-category-dropdown form-control"
                          >
                            <option value="">Select category</option>
                            {dropdownOptions.map((option, i) => (
                              <option key={i} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>

                          {/* Upload button */}
                          <button
                            type="button"
                            onClick={() => handleUpload(index)}
                            // disabled={!fileObj.file || !fileObj.category || fileObj.status === 'uploaded'}
                            // style={{
                            //   color: (fileObj.file || fileObj.category || fileObj.status !== 'uploaded') && "white",
                            //   backgroundColor: (fileObj.file || fileObj.category || fileObj.status !== 'uploaded') && "#4d9d30",
                            // }}
                            className="upload-button form-control"
                          >
                            {fileObj.status === "uploaded" ? "Uploaded" : "Upload"}
                          </button>

                          {/* Add more files button, shown only if all files are uploaded */}
                          {allFilesUploaded && index === files.length - 1 && (
                            <button
                              type="button"
                              onClick={addMoreFile}
                              className="add-more-button form-control"
                            >
                              Add More Files
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
        }
      </section>
    </div>
  );
}
