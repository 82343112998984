import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer"
import GetAIdiagonstics from "./sagas";



const sagaMiddleware = createSagaMiddleware();
const store= configureStore(
    {
        reducer:rootReducer,
        middleware: ()=>[sagaMiddleware]
    }
)

sagaMiddleware.run(GetAIdiagonstics)
export default store