
import { Footer } from "../components/Footer";
import Header from "../components/Header";
import { HomePage } from "../views/home/HomePage";

const Home=()=>{
    window.scrollTo(0, 0);

    return(
        <>
        <Header/>
        <HomePage/>
        <Footer/>
        </>
    )
}


export default Home;