import { CREATE_ACCOUNT, GET_INFO, /*GET_INFO_SUCCEEDED,*/ GET_SIGNUP, LOGOUT, MANAGE_PATIENT, PATIENT_INFORMATION, REPORT_TYPE, /*PATIENT_MEDICINE_RECOMMENDATION, PATIENT_REPORT*/ } from "./constant"

export const loginApp = (loginDetail) =>{
    return {
        type: GET_INFO,
        data: loginDetail,
    }
}


export const signUp = (signupDetail) =>{
    return {
        type: GET_SIGNUP,
        data: signupDetail,
    }
}



export const logoutAction = () =>{
    return {
        type: LOGOUT,
    }
}

export const accountCreate = (accountDetail) =>{
    return {
        type: CREATE_ACCOUNT,
        data: accountDetail,
    }
}

export const patientManage = (patientDetail) =>{
    return {
        type: MANAGE_PATIENT,
        data: patientDetail,
    }
}

export const patientInformation = () => {
    return {
        type: PATIENT_INFORMATION
    }
}

export const getReportType = () => {
    return {
        type: REPORT_TYPE
    }
}

// export const patientMedicineRecommendation = (patientDetail) => {
//     return {
//         type: PATIENT_MEDICINE_RECOMMENDATION,
//         data: patientDetail
//     }
// }

// export const patientReport = (patientDetail) => {
//     return {
//         type: PATIENT_REPORT,
//         data: patientDetail
//     }
// }