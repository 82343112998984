import Cookies from "js-cookie";
import { call, put, take, takeEvery } from "redux-saga/effects";
import {
  CREATE_ACCOUNT,
  GET_CREATE_ACCOUNT_FAILED,
  GET_CREATE_ACCOUNT_HIT,
  GET_CREATE_ACCOUNT_SUCCESSED,
  GET_INFO,
  GET_INFO_FAILED,
  GET_INFO_HIT,
  GET_INFO_SUCCEEDED,
  GET_MANAGE_PATIENT_FAILED,
  GET_MANAGE_PATIENT_HIT,
  GET_MANAGE_PATIENT_SUCCESSED,
  GET_PATIENT_INFORMATION_FAILED,
  GET_PATIENT_INFORMATION_HIT,
  GET_PATIENT_INFORMATION_SUCCESSED,
  GET_PATIENT_MEDICINE_RECOMMENDATION_FAILED,
  GET_PATIENT_MEDICINE_RECOMMENDATION_HIT,
  GET_PATIENT_MEDICINE_RECOMMENDATION_SUCCESSED,
  GET_PATIENT_REPORT_FAILED,
  GET_PATIENT_REPORT_HIT,
  GET_PATIENT_REPORT_SUCCESSED,
  GET_REPORT_TYPE_FAILED,
  GET_REPORT_TYPE_HIT,
  GET_REPORT_TYPE_SUCCESSED,
  GET_SIGNUP,
  GET_SIGNUP_FAILED,
  GET_SIGNUP_HIT,
  GET_SIGNUP_SUCCEEDED,
  MANAGE_PATIENT,
  PATIENT_INFORMATION,
  PATIENT_MEDICINE_RECOMMENDATION,
  PATIENT_REPORT,
  REPORT_TYPE,
} from "./constant";

import { CallWithAuth, CallWithOutAuth } from "../action/apiActions";
import {
  CREATE_ACCOUNT_API_URL,
  LOGIN_API_URL,
  MANAGE_PATIENT_API_URL,
  PATIENT_INFORMATION_API_URL,
  PATIENT_REPORT_API_URL,
  REPORT_TYPE_API_URL,
  SIGNUP_API_URL,
} from "../action/apiPath";

function* GetLogin(action) {
  yield put({
    type: GET_INFO_HIT,
  });

  try {
      const { consent, ...updatedData } = action.data;
        // const body = action.data
        const body = updatedData;
        const response = yield call(CallWithOutAuth, 'POST', LOGIN_API_URL, body);

    if (response.res.status == 200 || response.res.status == 201) {
      // Dispatch the success action after the API call succeeds
      yield put({
        type: GET_INFO_SUCCEEDED,
        data: response.res.data,
      });
      
        if (consent) {
                Cookies.set("login", JSON.stringify(action.data));
            }

      localStorage.setItem("accessToken", response.res.data.data.access_token);
      localStorage.setItem("email", response.res.data.data.user.email);
      localStorage.setItem(
        "subscription_id",
        response.res.data.data.user.subscription.subscription_id
      );
      // localStorage.setItem("info", response.res.data.data.user.info)
      localStorage.setItem("info", JSON.stringify(response.res.data.data.user.info));
    } else {
      // Handle API failure (optional)
      if (Array.isArray(response.res.data.message)) {
        yield put({
          type: GET_INFO_FAILED,
          error: response.res.data.message[0], // Example error handling
        });
      } else {
        yield put({
          type: GET_INFO_FAILED,
          error: response.res.data.message, // Example error handling
        });
      }
    }
  } catch (err) {
    if (Array.isArray(err)) {
      yield put({
        type: GET_INFO_FAILED,
        error: err[0], // Example error handling
      });
    } else {
      yield put({
        type: GET_INFO_FAILED,
        error: err, // Example error handling
      });
    }
  }
}

function* GetSignin(action) {
  yield put({
    type: GET_SIGNUP_HIT,
  });

  try {
    const body = action.data;
    const response = yield call(CallWithOutAuth, "POST", SIGNUP_API_URL, body);

    if (response.res.status == 200 || response.res.status == 201) {
      // Dispatch the success action after the API call succeeds
      yield put({
        type: GET_SIGNUP_SUCCEEDED,
        data: response.res.data,
      });

      localStorage.setItem("accessToken", response.res.data.data.access_token);
      localStorage.setItem("email", response.res.data.data.user.email);
      localStorage.setItem(
        "subscription_id",
        response.res.data.data.user.subscription.subscription_id
      );
      // localStorage.setItem("info", response.res.data.data.user.info)
    } else {
      // Handle API failure (optional)
      if (Array.isArray(response.res.data.message)) {
        yield put({
          type: GET_SIGNUP_FAILED,
          error: response.res.data.message[0], // Example error handling
        });
      } else {
        yield put({
          type: GET_SIGNUP_FAILED,
          error: response.res.data.message, // Example error handling
        });
      }
    }
  } catch (err) {
    if (Array.isArray(err)) {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err[0], // Example error handling
      });
    } else {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err, // Example error handling
      });
    }
  }
}

function* CreateAccount(action) {

  yield put({
    type: GET_CREATE_ACCOUNT_HIT,
  });

  console.log(action.data.file[0].name);
  try {
    const formdata = new FormData();
    formdata.append("dr_role", action.data.dr_role);
    formdata.append(
      "practice_framework_id",
      action.data.practice_framework_id
    );
    formdata.append(
      "diagnostic_tool_id",
      action.data.diagnostic_tool_id
    );
    formdata.append("file", action.data.file[0], action.data.file[0].name);

    const body = formdata;
    const response = yield call(
      CallWithAuth,
      "POST",
      CREATE_ACCOUNT_API_URL,
      body
    );


    if (response.res.status == 200 || response.res.status == 201) {
      // Dispatch the success action after the API call succeeds
      yield put({
        type: GET_CREATE_ACCOUNT_SUCCESSED,
        data: response.res.data,
      });
    } else {

      // Handle API failure (optional)
      if (Array.isArray(response.res.data.message)) {
        yield put({
          type: GET_CREATE_ACCOUNT_FAILED,
          error: response.res.data.message[0], // Example error handling
        });
      } else {
        console.log("r",response)

        yield put({
          type: GET_CREATE_ACCOUNT_FAILED,
          error: response.res.data.message, // Example error handling
        });
      }
    }
  } catch (err) {
    if (Array.isArray(err)) {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err[0], // Example error handling
      });
    } else {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err, // Example error handling
      });
    }
  }
}


function* ManagePatient(action) {
  yield put({
    type: GET_MANAGE_PATIENT_HIT,
  });

  try {

    const response = yield call(
      CallWithAuth,
      "POST",
      MANAGE_PATIENT_API_URL,
      action.data
    );

    action?.data?.cb(response)
    if (response.res.status == 200 || response.res.status == 201) {
      // Dispatch the success action after the API call succeeds
      console.log({response})
      yield put({
        type: GET_MANAGE_PATIENT_SUCCESSED,
        data: response.res.data,
      });
    } else {

      // Handle API failure (optional)
      if (Array.isArray(response.res.data.message)) {
        yield put({
          type: GET_MANAGE_PATIENT_FAILED,
          error: response.res.data.message[0], // Example error handling
        });
      } else {
        console.log("r",response)

        yield put({
          type: GET_MANAGE_PATIENT_FAILED,
          error: response.res.data.message, // Example error handling
        });
      }
    }
  } catch (err) {
    if (Array.isArray(err)) {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err[0], // Example error handling
      });
    } else {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err, // Example error handling
      });
    }
  }
}

function* PatientInformation() {
  yield put({
    type: GET_PATIENT_INFORMATION_HIT,
  });

  try {
    const response = yield call(
      CallWithAuth,
      "GET",
      PATIENT_INFORMATION_API_URL
    );


    if (response.res.status == 200 || response.res.status == 201) {
      // Dispatch the success action after the API call succeeds
      console.log({response})
      yield put({
        type: GET_PATIENT_INFORMATION_SUCCESSED,
        data: response.res.data,
      });
    } else {

      // Handle API failure (optional)
      if (Array.isArray(response.res.data.message)) {
        yield put({
          type: GET_PATIENT_INFORMATION_FAILED,
          error: response.res.data.message[0], // Example error handling
        });
      } else {
        console.log("r",response)

        yield put({
          type: GET_PATIENT_INFORMATION_FAILED,
          error: response.res.data.message, // Example error handling
        });
      }
    }
  } catch (err) {
    if (Array.isArray(err)) {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err[0], // Example error handling
      });
    } else {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err, // Example error handling
      });
    }
  }
}

function* GetReportType() {
  yield put({
    type: GET_REPORT_TYPE_HIT,
  });

  try {
    const response = yield call(
      CallWithAuth,
      "GET",
      REPORT_TYPE_API_URL
    );


    if (response.res.status == 200 || response.res.status == 201) {
      // Dispatch the success action after the API call succeeds
      console.log({response})
      yield put({
        type: GET_REPORT_TYPE_SUCCESSED,
        data: response.res.data,
      });
    } else {

      // Handle API failure (optional)
      if (Array.isArray(response.res.data.message)) {
        yield put({
          type: GET_REPORT_TYPE_FAILED,
          error: response.res.data.message[0], // Example error handling
        });
      } else {
        console.log("r",response)

        yield put({
          type: GET_REPORT_TYPE_FAILED,
          error: response.res.data.message, // Example error handling
        });
      }
    }
  } catch (err) {
    if (Array.isArray(err)) {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err[0], // Example error handling
      });
    } else {
      yield put({
        type: GET_SIGNUP_FAILED,
        error: err, // Example error handling
      });
    }
  }
}

// function* PatientMedicineRecommendation() {
//   yield put({
//     type: GET_PATIENT_MEDICINE_RECOMMENDATION_HIT,
//   });

//   try {
//     const response = yield call(
//       CallWithAuth,
//       "GET",
//       PATIENT_INFORMATION_API_URL //need to change this
//     );


//     if (response.res.status == 200 || response.res.status == 201) {
//       // Dispatch the success action after the API call succeeds
//       console.log({response})
//       yield put({
//         type: GET_PATIENT_MEDICINE_RECOMMENDATION_SUCCESSED,
//         data: response.res.data,
//       });
//     } else {

//       // Handle API failure (optional)
//       if (Array.isArray(response.res.data.message)) {
//         yield put({
//           type: GET_PATIENT_MEDICINE_RECOMMENDATION_FAILED,
//           error: response.res.data.message[0], // Example error handling
//         });
//       } else {
//         console.log("r",response)

//         yield put({
//           type: GET_PATIENT_MEDICINE_RECOMMENDATION_FAILED,
//           error: response.res.data.message, // Example error handling
//         });
//       }
//     }
//   } catch (err) {
//     if (Array.isArray(err)) {
//       yield put({
//         type: GET_SIGNUP_FAILED,
//         error: err[0], // Example error handling
//       });
//     } else {
//       yield put({
//         type: GET_SIGNUP_FAILED,
//         error: err, // Example error handling
//       });
//     }
//   }
// }


// function* PatientReport(action) {
//   yield put({
//     type: GET_PATIENT_REPORT_HIT,
//   });

//   console.log(action.data)
//   try {
//     const response = yield call(
//       CallWithAuth,
//       "GET",
//       `${PATIENT_REPORT_API_URL}${action.data}`
//     );


//     if (response.res.status == 200 || response.res.status == 201) {
//       // Dispatch the success action after the API call succeeds
//       console.log({response})
//       yield put({
//         type: GET_PATIENT_REPORT_SUCCESSED,
//         data: response.res.data,
//       });
//     } else {

//       // Handle API failure (optional)
//       if (Array.isArray(response.res.data.message)) {
//         yield put({
//           type: GET_PATIENT_REPORT_FAILED,
//           error: response.res.data.message[0], // Example error handling
//         });
//       } else {
//         console.log("r",response)

//         yield put({
//           type: GET_PATIENT_REPORT_FAILED,
//           error: response.res.data.message, // Example error handling
//         });
//       }
//     }
//   } catch (err) {
//     if (Array.isArray(err)) {
//       yield put({
//         type: GET_SIGNUP_FAILED,
//         error: err[0], // Example error handling
//       });
//     } else {
//       yield put({
//         type: GET_SIGNUP_FAILED,
//         error: err, // Example error handling
//       });
//     }
//   }
// }

function* GetAIdiagonstics() {
  yield takeEvery(GET_INFO, GetLogin);
  yield takeEvery(GET_SIGNUP, GetSignin);
  yield takeEvery(CREATE_ACCOUNT, CreateAccount);
  yield takeEvery(MANAGE_PATIENT, ManagePatient);
  yield takeEvery(PATIENT_INFORMATION, PatientInformation);
  yield takeEvery(REPORT_TYPE, GetReportType);
  // yield takeEvery(PATIENT_MEDICINE_RECOMMENDATION, PatientMedicineRecommendation);
  // yield takeEvery(PATIENT_REPORT, PatientReport);
  
}

export default GetAIdiagonstics;
