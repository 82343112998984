import { Footer } from "../components/Footer"
import Header from "../components/Header"
import { SubscriptionPage } from "../views/subscription/subscriptionPage"

export const Subscription = () => {
    window.scrollTo(0, 0);

    return (
        <>
            <Header />
            {/* {console.log(typeof(localStorage.getItem('subscription_id')),localStorage.getItem('subscription_id'))}
            {localStorage.getItem('subscription_id') == 'null' ? <SubscriptionPage /> : 
            
            <div style={{ height: "100vh", display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                <h1>Already Purchased</h1>
            </div>} */}

            <SubscriptionPage />

            <Footer />
        </>
    )
}