import React, { useEffect, useState } from "react";
import patient from "../../assets/img/patient.jpg";
import { useParams } from "react-router";
import {
  PATIENT_PAITENT_REPORT_API_URL,
  PATIENT_REPORT_API_URL,
} from "../../action/apiPath";
import { CallWithAuth } from "../../action/apiActions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MetadataSection from "./MetadataSection";
import RecommendationSection from "./RecommendationSection";
import AssessmentSection from "./AssessmentSection";
import arrorRight from "../../assets/img/arrow-right.svg";
import { Link } from "react-router-dom";
import moment from "moment";

const PatientReportInformationPage = () => {
  const { id } = useParams();
  const [reportData, setReportData] = useState(null);
  const [reportPatientData, setReportPatientData] = useState(null);

  useEffect(() => {
    getPatientReportApiCall();
    getPatientReportRecordApiCall();
  }, []);

  const getPatientReportRecordApiCall = async () => {
    try {
      const response = await CallWithAuth(
        "GET",
        `${PATIENT_PAITENT_REPORT_API_URL}${id}`
      );
      if (response.res.data && response.res.data.statusCode === 200) {
        setReportPatientData(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching practice frameworks:", error);
    }
  };

  const getPatientReportApiCall = async () => {
    try {
      const response = await CallWithAuth(
        "GET",
        `${PATIENT_REPORT_API_URL}${id}`
      );
      if (response.res.data && response.res.data.statusCode === 200) {
        setReportData(response.res.data.data);
      }
    } catch (error) {
      console.error("Error fetching practice frameworks:", error);
    }
  };

  console.log("=============>reportData", reportData)

  return (
    <div>
      <section class="site_hero patient_management_banner">
        <div class="site_container">
          <div class="site_row">
            <div class="txt_colm">
            </div>
            <div class="img_colm">
              <div class="img_block">
                <img src={patient} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="foralign max-60">
        <div class="site_container text-center" style={{ padding: "20px" }}>
          <h3>CONFIDENTIAL PSYCHOLOGIST REPORT</h3>
          <h5>Name: {reportData?.full_name}</h5>
          <h5>Phone: {reportData?.phone}</h5>
          <h5>D.O.B: {moment(reportData?.dob).format("DD/MM/yyyy")}</h5>
          <h5>Date of Assessment: {moment(reportData?.created_at).format("DD/MM/yyyy")}</h5>
          {/* <h3>Assessor: {}</h3> */}
          {/* <h3>Date of Assessment: {}</h3> */}
        </div>
      </div>

      {reportData?.metadata && (
        <>
          <div style={{ textAlign: "center" }}>
            <h2>Metadata</h2>
          </div>
          <MetadataSection
            setReportData={setReportData}
            metadata={reportData.metadata}
          />
        </>
      )}
      {reportPatientData?.history && JSON.parse(reportPatientData?.history) && (
        <>
          <MetadataSection
            setReportData={setReportData}
            // metadata={reportData.history}
            metadata={JSON.parse(reportPatientData?.history || "")}

          />
        </>
      )}

      <div className="foralign max-60">
        <div class="site_container">
        <h3>Recommendations </h3><br /><br />
          <div>
            <span>The following is recommended for <strong>{reportData?.full_name}</strong>: </span>
          </div><br /><br />
          <div>
            <span>If you should have any questions or concerns about the content of this report, please get in contact with written parental consent from <strong>{reportData?.full_name}</strong>.  </span>
          </div><br /><br />
          <div>
            <span>cc: [auto populate patient’s treating doctor]</span>
          </div><br /><br />
          <div>
            <span>Warm Regards</span>
          </div><br /><br />
          <div>
            <span></span>
          </div>
          <div>
            <span><strong>{`${reportData?.user?.first_name} ${reportData?.user?.last_name}`}</strong></span>
          </div>
          <div>
            <span>Clinical Psychology Registrar/Psychologist (MAPS)</span>
          </div>
          <div>
            <span><strong>{`${reportData?.user?.email}`}</strong> </span>
          </div>
          <div>
            <span>AHPRA No: PSY0002178238 </span>
          </div>
        </div>
      </div>

      {reportData?.assessment && (
        <>
          <div style={{ textAlign: "center" }}>
            <h2>Assessment</h2>
          </div>
          <AssessmentSection
            setReportData={setReportData}
            metadata={reportData.assessment}
          />
        </>
      )}
      {reportData?.recommendation && (
        <>
          <div style={{ textAlign: "center" }}>
            <h2>Recommendation</h2>
          </div>
          <RecommendationSection
            setReportData={setReportData}
            initialRecommendation={reportData.recommendation}
          />
        </>
      )}

      {/* <div class="btn_row">
        <Link to={"#"} class="solid_cta cta_primary cta_with_icon">
          <span>Save</span> <img src={arrorRight} alt="" />
        </Link>
      </div> */}
    </div>
  );
};

export default PatientReportInformationPage;
