import "./loginSignup.scss";
import signup_bg from "../../assets/img/signup_bg.jpg";
import logo from "../../assets/img/logo.svg";
import password_show from "../../assets/img/password_show.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Loading from 'react-fullscreen-loading';
import { signUp } from "../../redux/action";
import { Link } from "react-router-dom";


const Signup = () => {
    const navigate = useNavigate()
    // __________ selector from redux __________
    const selector = useSelector((state) => state.GET_SIGNUP_REDUCER)
    // __________ selector from redux __________


    // __________ state management __________
    const [consent, setConsent] = useState(false)
    const [emailError, setEmailError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    const [constError, setConstError] = useState(null)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const [signupDetail, setSignupDetail] = useState(
        {
            email: null,
            password: null
        }
    )
    // __________ state management __________


    // __________ handleFunction __________

    const handleEmail = (e) => {
        setSignupDetail(prevState => ({
            ...prevState, // Spread the previous state to keep the password
            email: e.target.value  // Update the email
        }));
    }

    const handlePassword = (e) => {
        setSignupDetail(prevState => ({
            ...prevState, // Spread the previous state to keep the password
            password: e.target.value // Update the email
        }));
    }

    const handleSignUpEffect = () => {
        if (selector.error == null) {
            if (selector.data != null) {
                toast.success(selector.message, {
                    autoClose: 2000,
                    position: "bottom-right",
                })
                setTimeout(() => {
                    navigate("/subscription"); // Navigate after 2 seconds
                }, 1000);
            }
        } else {
            toast.error(selector.error, {
                autoClose: 2000,
                position: "bottom-right",
            })
        }
    }

    // __________ handleFunction __________


    // __________ Redux Action __________
    const dispatch = useDispatch()

    const handleSubmitFormLogin = (event) => {
        event.preventDefault();
        setEmailError("");
        setPasswordError("");
        setConstError("")

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!signupDetail.email) {
            setEmailError("* Email is required.");
            return;
        } else if (!emailRegex.test(signupDetail.email)) {
            setEmailError("* Invalid email format.");
            return;
        }

        const passwordStrengthRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;

        // Password validation (for example, a minimum of 8 characters)
        if (!signupDetail.password) {
            setPasswordError("* Password is required.");
            return;
        } else if (signupDetail.password.length < 8) {
            setPasswordError("* Password must be at least 8 characters long.");
            return;
        } else if (!passwordStrengthRegex.test(signupDetail.password)) {
        setPasswordError("* Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.");
        return;
    }

        if (consent) {
            dispatch(signUp(signupDetail))
        } else {
            setConstError('* Accept Terms and Conditions ')
        }
    }
    // __________ Redux Action __________



    useEffect(() => {
        handleSignUpEffect()
    }, [selector])


    console.log(selector)

    return (
        <>

            {selector.isLoading && <Loading loading background="rgba(236, 240, 241, 0.7)" loaderColor="#3498db" />}


            <section class="login_signup_wrapper">
                <div class="img_colm">
                    <div href="" class="back_btn" onClick={() => navigate('/')}>
                        <i class="fa-solid fa-chevron-left"></i>
                    </div>
                    <div class="img_block">
                        <img src={signup_bg} alt="" />
                    </div>
                </div>
                <div class="form_colm">
                    <div class="inner_block">
                        <img src={logo} alt="" class="logo_img" />
                        <h1>Sign Up</h1>
                        <p>Join us and experience a revolutionary way to manage patient files and generate comprehensive
                            reports.</p>

                        <form onSubmit={handleSubmitFormLogin} class="form_block">
                            <div class="form_field">
                                <div class="input_grp">
                                    <label for="">Email</label>
                                    <input type="text" onChange={handleEmail} />
                                </div>
                                <span className="red_error">{emailError}</span>
                            </div>
                            <div class="form_field">
                                <div class="input_grp">
                                    <label for="">Password</label>
                                    <div class="pass_field">
                                        <input type={isPasswordVisible ? "text" : "password"} onChange={handlePassword} />
                                        <span class={`show_hide_btn ${!isPasswordVisible ? 'hide' : ''}`} onClick={()=>setIsPasswordVisible(!isPasswordVisible)}>
                                            <img src={password_show} alt="" />
                                        </span>
                                    </div>
                                    <span className="red_error">{passwordError}</span>
                                </div>
                            </div>
                            <div class="site_row">
                                <div class="input_grp">
                                    <input type="checkbox" id="consentCheckbox"  checked={consent} onChange={(e) => setConsent(e.target.checked)} />
                                    <label for="" htmlFor="consentCheckbox" > &nbsp;I have read and agree with the Terms and Conditions and Privacy Policy</label>
                                </div>
                                <span className="red_error">{constError}</span>
                            </div>
                            <div class="btn_block">
                                <button type="submit">Signup</button>
                            </div>
                            <p>Already have an account? <Link to={'/login'}> &nbsp; Sign In</Link></p>
                        </form>
                    </div>
                </div>
            </section>

            <ToastContainer />

        </>
    )
}

export default Signup
