import rightArrow from "../../assets/img/arrow-right.svg"
import freePlan from "../../assets/img/free_plan.svg"
import regular from "../../assets/img/regular.svg"
import silver from "../../assets/img/silver.svg"
import gold from "../../assets/img/gold.svg"
import { useEffect, useState } from "react"
import { CallWithAuth, CallWithOutAuth } from "../../action/apiActions"
import { GET_SUBSCRIPTION, INIT_SUBSCRIPTION } from "../../action/apiPath"
import { toast, ToastContainer } from "react-toastify"
import { loadStripe } from "@stripe/stripe-js"
import { Link, useNavigate } from "react-router-dom"

export const SubscriptionPage = () => {

    const subscription_id = localStorage.getItem("subscription_id")
    const [subscriptionDetail, setSubscriptionDetail] = useState(null)
    const navigate = useNavigate()

    const subscriptionList = async () => {
        try {
            const response = await CallWithAuth('GET', GET_SUBSCRIPTION)
            if (response.res.status == 200) {
                setSubscriptionDetail(response.res.data.data)
                toast.success(response.res.data.message, {
                    autoClose: 2000,
                    position: "bottom-right",
                })
            } else {
                toast.error(response.res.data.message, {
                    autoClose: 2000,
                    position: "bottom-right",
                })
            }
        } catch (err) {
            toast.error(err, {
                autoClose: 2000,
                position: "bottom-right",
            })
        }
    }

    useEffect(() => {
        subscriptionList()
    }, [])


    const [selectedID, setSelectedID] = useState(null)

    const Selectsubscription = (e) => {
        setSelectedID(e)
    }

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const isTokenAvailable = localStorage.getItem('accessToken')
        if (isTokenAvailable) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    }, []);



    const MakePayment = async () => {
        if(!isLoggedIn){
           setTimeout(() => {
                navigate('/login')
            },1500)
            return toast.warn("Please Login to buy subscription plan.", {
                autoClose: 2000,
                position: "bottom-right",
            })
        }

        if (selectedID == null) {
            toast.warn("Select any subscription plan.", {
                autoClose: 2000,
                position: "bottom-right",
            })
        } else {
            try {
                const body = {
                    "subscription_id": selectedID
                }
                const response = await CallWithAuth('POST', INIT_SUBSCRIPTION, body)
                if (response.res.status == 200 || response.res.status == 201) {
                    StripeCall(response.res.data.data.session_id)
                    setSubscriptionDetail(response.res.data.data)
                    toast.success(response.res.data.message, {
                        autoClose: 2000,
                        position: "bottom-right",
                    })
                }
               return toast.error(response.res.data.message, {
                    autoClose: 2000,
                    position: "bottom-right",
                })
            } catch (err) {
                toast.error(err, {
                    autoClose: 2000,
                    position: "bottom-right",
                })
            }
        }

    }


    const StripeCall = async (e) => {
        const stripe = await loadStripe('pk_test_51Q0bpAGzDnn7G9w0Z6Ox8g3nqjJBbXbQviAEKB0db5a20p839XKpGvwiImqSMJWNc0bQsSivsDXxzwLU9VXVx7qN00dezUw73V')

        const result = stripe.redirectToCheckout({
            sessionId: e
        });

        console.log("==========result", result)

        if (result.error) {
            toast.error(result.error, {
                autoClose: 2000,
                position: "bottom-right",
            })
            console.log(result.error)
        }
    }


    const getPlanDetails = (planName) => {
        let planClass = "";
        let planImage = null;
        let additionalContent = null;

        if (planName === "Regular Plan") {
            planClass = "regular_plan";
            planImage = regular; // Assuming you have imported the 'regular' image
        } else if (planName === "Gold Plan") {
            planClass = "gold_plan";
            planImage = gold; // Assuming you have imported the 'gold' image
        } else if (planName === "Silver Plan") {
            planClass = "silver_plan";
            planImage = silver; // Assuming you have imported the 'silver' image

            // Add additional content for Silver Plan
            additionalContent = <span class="tag">Most Popular</span>;
        }

        return { planClass, planImage, additionalContent };
    };

    return (
        <>
            <section class="create_account_block">
                <div class="site_container">
                    <div className="all_steps">
                        <div class="step_block">
                            <span class="active"></span>
                            <span class="active"></span>
                        </div>
                        <div class="site_row btn_block">
                            <Link to={'/'} class="back_btn"><img src={rightArrow} alt="" /> <span>Back</span></Link>
                            <div class="btn_row">
                                <Link to={'/'} class="outlined_cta cta_outline cta_with_icon"><span>Close</span> </Link>
                                <a onClick={() => MakePayment()} class="solid_cta cta_primary cta_with_icon"><span>Payment Now</span> <img
                                    src={rightArrow} alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div class="heading_block">
                        <span class="tag">Account Setup</span>
                        <h1>Choose Subscription</h1>
                        <p>Choose the subscription tier that suits your needs and budget</p>
                    </div>
                    <div class="plan_packages">


                        {subscriptionDetail?.map((items, i) => {
                            const { planClass, planImage, additionalContent } = getPlanDetails(items.name);
                            const isSubscribed = subscription_id === items.id; // Check if the subscription_id matches
                            return (
                                <div class={`pkg_item ${planClass}`} key={items.id}>
                                    {/* Add the additional content for Silver Plan */}
                                    {additionalContent}

                                    <div class="pkg_header">
                                        <div class="icon_block">
                                            <img src={planImage} alt="" />
                                        </div>
                                        <h5>{items.name}</h5>
                                        <div class="plan_price">
                                            <h6><sup>$</sup>{items.price}<sub>/mo</sub></h6>
                                        </div>
                                    </div>

                                    <div class="pkg_body">
                                        <span class="txt">Includes</span>
                                        <div dangerouslySetInnerHTML={{ __html: items.description }} />

                                        <div class="plan_select_check" /*onClick={() => Selectsubscription(items.id)}*/ onClick={!isSubscribed ? () => Selectsubscription(items.id) : undefined}>
                                            <input type="radio" name="plan" />
                                            <div class="check_ui">
                                                <span>{isSubscribed ? "Already subscribed" : (items.id === selectedID ? "Selected" : "Select this plan")}</span>
                                                {!isSubscribed ? <img src={rightArrow} alt="" /> : null }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        {/* <div class="pkg_item free_plan">
                            <span class="tag">14-day free trial</span>
                            <div class="pkg_header">
                                <div class="icon_block">
                                    <img src={freePlan} alt="" />
                                </div>
                                <h5>Free Plan</h5>
                                <div class="plan_price">
                                    <h6><sup>$</sup>0<sub>/mo</sub></h6>
                                </div>
                            </div>
                            <div class="pkg_body">
                                <span class="txt">Includes</span>
                                <ul>
                                    <li>Access to basic mental health assessments (e.g., anxiety, stress, and mood tracking)
                                    </li>
                                    <li>Weekly AI-generated mental health reports</li>
                                    <li>Personalized recommendations for self-care activities</li>
                                    <li>Limited access to mindfulness exercises and breathing techniques</li>
                                    <li>Community forum access for peer support</li>
                                    <li>Standard customer support</li>
                                </ul>

                                <div class="plan_select_check">
                                    <input type="radio" name="plan" />
                                    <div class="check_ui">
                                        <span>Select this plan</span>
                                        <img src={rightArrow} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pkg_item regular_plan">
                            <div class="pkg_header">
                                <div class="icon_block">
                                    <img src={regular} alt="" />
                                </div>
                                <h5>Regular Plan</h5>
                                <div class="plan_price">
                                    <h6><sup>$</sup>20<sub>/mo</sub></h6>
                                </div>
                            </div>
                            <div class="pkg_body">
                                <span class="txt">Includes</span>
                                <ul>
                                    <li>Access to basic mental health assessments (e.g., anxiety, stress, and mood tracking)
                                    </li>
                                    <li>Weekly AI-generated mental health reports</li>
                                    <li>Personalized recommendations for self-care activities</li>
                                    <li>Limited access to mindfulness exercises and breathing techniques</li>
                                    <li>Community forum access for peer support</li>
                                    <li>Standard customer support</li>
                                </ul>


                                <div class="plan_select_check">
                                    <input type="radio" name="plan" />
                                    <div class="check_ui">
                                        <span>Select this plan</span>
                                        <img src={rightArrow} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pkg_item silver_plan">
                            <span class="tag">Most Popular</span>
                            <div class="pkg_header">
                                <div class="icon_block">
                                    <img src={silver} alt="" />
                                </div>
                                <h5>Silver Plan</h5>
                                <div class="plan_price">
                                    <h6><sup>$</sup>35<sub>/mo</sub></h6>
                                </div>
                            </div>
                            <div class="pkg_body">
                                <span class="txt">Includes</span>
                                <ul>
                                    <li>Access to basic mental health assessments (e.g., anxiety, stress, and mood tracking)
                                    </li>
                                    <li>Weekly AI-generated mental health reports</li>
                                    <li>Personalized recommendations for self-care activities</li>
                                    <li>Limited access to mindfulness exercises and breathing techniques</li>
                                    <li>Community forum access for peer support</li>
                                    <li>Standard customer support</li>
                                </ul>


                                <div class="plan_select_check">
                                    <input type="radio" name="plan" />
                                    <div class="check_ui">
                                        <span>Select this plan</span>
                                        <img src={rightArrow} alt="/" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pkg_item gold_plan">
                            <div class="pkg_header">
                                <div class="icon_block">
                                    <img src={gold} alt="" />
                                </div>
                                <h5>Gold Plan</h5>
                                <div class="plan_price">
                                    <h6><sup>$</sup>50<sub>/mo</sub></h6>
                                </div>
                            </div>
                            <div class="pkg_body">
                                <span class="txt">Includes</span>
                                <ul>
                                    <li>Access to basic mental health assessments (e.g., anxiety, stress, and mood tracking)
                                    </li>
                                    <li>Weekly AI-generated mental health reports</li>
                                    <li>Personalized recommendations for self-care activities</li>
                                    <li>Limited access to mindfulness exercises and breathing techniques</li>
                                    <li>Community forum access for peer support</li>
                                    <li>Standard customer support</li>
                                </ul>


                                <div class="plan_select_check">
                                    <input type="radio" name="plan" />
                                    <div class="check_ui">
                                        <span>Select this plan</span>
                                        <img src={rightArrow} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div> */}


                    </div>
                </div>
            </section>

            <ToastContainer />

        </>
    )
}