import React, { useState } from 'react';

const RecommendationSection = ({ initialRecommendation }) => {
    const [recommendation, setRecommendation] = useState(initialRecommendation);

    const handleChange = (event) => {
        setRecommendation(event.target.value);
    };

    return (
        <>
         <div className="foralign max-60">
            <div class="site_container">

                <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
                    <h3>Recommendation</h3>
                    <textarea
                        value={recommendation}
                        onChange={handleChange}
                        rows={10}
                        cols={50}
                        style={{
                            width: '100%',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            padding: '10px',
                            fontSize: '14px',
                            resize: 'vertical',
                        }}
                    />
                
                </div>
            </div>
        
        
            <div class="site_container">
                <form>
                    {/* <pre>{JSON.stringify(recommendation, null, 2)}</pre>  */}
                </form>
            </div>
        </div>
        </>
    );
};

export default RecommendationSection;
