import "../assets/scss/global.scss"
import logo from "../assets/img/logo.svg"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import userIcon from '../assets/img/user_icon_sm.svg';
import logoutIcon from '../assets/img/logout_icon.svg';
import { useDispatch } from "react-redux";
import { logoutAction } from "../redux/action";

const Header = () => {

    const location = useLocation();

    const navigate = useNavigate()

    const [isSticky, setIsSticky] = useState(false);

    const [menuBar, setMenubar] = useState(false);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const isTokenAvailable = localStorage.getItem('accessToken')
        if (isTokenAvailable) {
            setIsLoggedIn(true)
        } else {
            setIsLoggedIn(false)
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const top = window.scrollY;
            if (top > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures this effect runs once when the component mounts


    const login = () => {
        navigate('/login')
    }

    const disptach = useDispatch()

    const logout = () => {
        localStorage.clear();
        navigate('/')
        disptach(logoutAction())
    }

    const home = () => {
        navigate('/')
    }






    return (
        <>
            <header className={`site_header ${isSticky ? 'sticky_header' : ''}`}>
                <div className="site_container">
                    <div className="site_navbar">
                        <div className="site_brand">
                            <a onClick={() => home()}>
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div className={menuBar ? "site_menus menu_show" : "site_menus"}>
                            <ul className="site_menu_bar">
                                {/* <li className="active_page"><Link to={'/'}>Home</Link></li> */}
                                <li className={location.pathname === '/' ? 'active_page' : ''}><Link to="/">Home</Link></li>
                                <li className={location.pathname === '/about' ? 'active_page' : ''}><a href="/about">About Us</a></li>
                                <li className={location.pathname === '/services' ? 'active_page' : ''}><a href="/services">Services</a></li>
                                <li className={location.pathname === '/contact-us' ? 'active_page' : ''}><a href="/contact-us">Contact Us</a></li>
                                {/* <li><Link to={'/patient-information'}>Patient Information</Link></li> */}
                                {isLoggedIn ? <li className={location.pathname === '/patient-information' ? 'active_page' : ''}><Link to="/patient-information">Patient Information</Link></li> : null}
                            </ul>



                            {localStorage.getItem('accessToken') != null ? <>
                                <div className="user_panel">
                                    <div className="avatar" style={{ cursor: "pointer" }}>
                                        <span>AB</span>
                                    </div>
                                    <div className="user_drop">
                                        <ul>
                                            <li><Link to={'/create-account'}><img src={userIcon} alt="" /> <span>
                                                Profile</span></Link>
                                            </li>
                                            <li><a style={{ cursor: 'pointer' }} onClick={() => logout()}><img src={logoutIcon} alt="" /> <span>Logout</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </> : <>
                                <div className="login_btns">
                                    <Link to={'/signup'} className="solid_cta cta_white"> Signup</Link>
                                    <Link to={'/login'} className="solid_cta cta_primary"> Login</Link>
                                </div>
                            </>}


                        </div>
                        <span className={menuBar ? "menu_toggler toggled" : "menu_toggler"} onClick={() => setMenubar(!menuBar)}>
                            <span></span>
                        </span>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
